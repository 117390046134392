@charset "UTF-8";
/*
 THEMING
*/
@media only screen and (max-width: 767px) {
  html,
  body {
    font-size: 14px !important; } }

@media only screen and (min-width: 768px) {
  html,
  body {
    font-size: 15px !important; } }

@media only screen and (min-width: 1200px) {
  html,
  body {
    font-size: 16px !important; } }

/* MIXINIS FOR WEBFORMS FOR MARKETERS STYLING */
/* MIXINIS FOR WEBFORMS FOR MARKETERS STYLING */
/*
  Generic styling for the mega-menu for it to be display as a list of elements
  Target the dgs-mega-menu, which is build up on nested unordered lists

  IMPORTANT: If you want a specific change for the mega-menu add it on a project file.
 */
#wrapper ul.dgs-mega-menu,
#wrapper ul.dgs-mega-menu ul {
  list-style: none; }

#wrapper ul.dgs-mega-menu > li {
  padding: 0 1rem;
  display: inline-block; }
  #wrapper ul.dgs-mega-menu > li > a {
    display: inline-block;
    padding: 1rem 0;
    position: relative; }
    #wrapper ul.dgs-mega-menu > li > a.active {
      position: relative; }
    #wrapper ul.dgs-mega-menu > li > a.active:before, #wrapper ul.dgs-mega-menu > li > a.current:before {
      height: 0.3rem;
      background-color: lightgrey;
      visibility: visible;
      content: "";
      position: absolute;
      width: 100%;
      bottom: 0;
      left: 0;
      transform: scaleX(1); }
  #wrapper ul.dgs-mega-menu > li > ul {
    width: 100%;
    display: none;
    right: 0;
    left: 0;
    position: absolute;
    z-index: 1;
    padding: 2.5rem 0;
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.1) 0 0.25rem 0.25rem 0; }
    #wrapper ul.dgs-mega-menu > li > ul a {
      display: inline-block;
      padding: 0.5rem 0; }
      #wrapper ul.dgs-mega-menu > li > ul a.as-text {
        cursor: default; }
    #wrapper ul.dgs-mega-menu > li > ul > li {
      display: inline-block;
      padding: 0 3rem;
      overflow-wrap: break-word;
      border-right: 0.05rem solid lightgrey;
      box-sizing: border-box;
      /* One - two items */
      /* Three elements */
      /* Four items */
      /* Five...seven items */ }
      #wrapper ul.dgs-mega-menu > li > ul > li:first-child:nth-last-child(1), #wrapper ul.dgs-mega-menu > li > ul > li:first-child:nth-last-child(2),
      #wrapper ul.dgs-mega-menu > li > ul > li:first-child:nth-last-child(2) ~ li {
        width: 50%; }
        #wrapper ul.dgs-mega-menu > li > ul > li:first-child:nth-last-child(1) > ul, #wrapper ul.dgs-mega-menu > li > ul > li:first-child:nth-last-child(2) > ul,
        #wrapper ul.dgs-mega-menu > li > ul > li:first-child:nth-last-child(2) ~ li > ul {
          columns: 2; }
      #wrapper ul.dgs-mega-menu > li > ul > li:first-child:nth-last-child(3),
      #wrapper ul.dgs-mega-menu > li > ul > li:first-child:nth-last-child(3) ~ li {
        width: 33.3333%; }
      #wrapper ul.dgs-mega-menu > li > ul > li:first-child:nth-last-child(4),
      #wrapper ul.dgs-mega-menu > li > ul > li:first-child:nth-last-child(4) ~ li {
        width: 25%; }
      #wrapper ul.dgs-mega-menu > li > ul > li:first-child:nth-last-child(5),
      #wrapper ul.dgs-mega-menu > li > ul > li:first-child:nth-last-child(5) ~ li, #wrapper ul.dgs-mega-menu > li > ul > li:first-child:nth-last-child(6),
      #wrapper ul.dgs-mega-menu > li > ul > li:first-child:nth-last-child(6) ~ li, #wrapper ul.dgs-mega-menu > li > ul > li:first-child:nth-last-child(7),
      #wrapper ul.dgs-mega-menu > li > ul > li:first-child:nth-last-child(7) ~ li {
        width: 20%; }
      #wrapper ul.dgs-mega-menu > li > ul > li:nth-child(n+6) {
        display: none; }
      #wrapper ul.dgs-mega-menu > li > ul > li:last-child {
        border-right: 0; }
      #wrapper ul.dgs-mega-menu > li > ul > li > a {
        font-weight: bold;
        padding-bottom: 0.5rem; }
      #wrapper ul.dgs-mega-menu > li > ul > li ul a {
        font-weight: normal; }

ul.dgs-burger-menu {
  list-style: none;
  box-shadow: 0 0.25rem 0.25rem rgba(0, 0, 0, 0.1); }
  ul.dgs-burger-menu, ul.dgs-burger-menu li:not(.last-navigation) > ul:not(.last-navigation) {
    list-style: none;
    background-color: white;
    display: block;
    position: absolute;
    width: 100%;
    padding-top: 2rem; }
  ul.dgs-burger-menu li {
    padding: 0.8rem 0; }
  ul.dgs-burger-menu a.as-text {
    cursor: default; }
  ul.dgs-burger-menu > li > ul {
    z-index: 1;
    top: 0; }
  ul.dgs-burger-menu.text-center li > a {
    width: 90%;
    margin: auto;
    padding: 0.5rem 0.2rem;
    display: block;
    text-align: center; }
  ul.dgs-burger-menu.text-left li {
    text-align: left; }
    ul.dgs-burger-menu.text-left li > a {
      width: 90%;
      margin: 0 0 0 1.5rem;
      padding: 0.5rem 0.2rem;
      display: inline-block; }
  ul.dgs-burger-menu.text-right li {
    text-align: right; }
    ul.dgs-burger-menu.text-right li > a {
      width: 90%;
      margin: 0 1.5rem 0 0;
      padding: 0.5rem 0.2rem;
      display: inline-block; }

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
font,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-weight: inherit;
  font-style: inherit;
  font-size: 100%;
  font-family: inherit;
  vertical-align: baseline;
  /* COG: this is known to cause problems in CQ4 and SharePoint */ }

/* remember to define focus styles! */
:focus {
  outline: 0; }

body {
  line-height: 1;
  background: white;
  position: relative;
  overflow: auto; }

/* tables still need 'cellspacing="0"' in the markup */
table {
  border-collapse: separate;
  border-spacing: 0; }

caption,
th,
td {
  text-align: left;
  font-weight: normal; }

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: ""; }

blockquote,
q {
  quotes: "" ""; }

/* COG: make sup and sup working again */
sub {
  vertical-align: sub;
  font-size: 0.9em; }

sup {
  vertical-align: super;
  font-size: 0.9em; }

* {
  box-sizing: border-box; }

select::-ms-expand {
  /* for IE 11 */
  display: none; }

html,
body {
  font-family: "Open Sans", "Arial", sans-serif;
  font-weight: 400;
  font-style: normal;
  color: inherit;
  line-height: 24px;
  color: #4d4d4d;
  background-color: #fff; }

h1,
.like-h1 {
  font-family: "Open Sans", "Arial", sans-serif;
  font-weight: 700;
  font-style: normal;
  font-size: 2.13rem;
  line-height: 1.2;
  color: inherit; }
  @media all and (-ms-high-contrast: none) {
    h1,
    .like-h1 {
      font-weight: bold; } }
  @media only screen and (max-width: 767px) {
    h1,
    .like-h1 {
      font-size: 2rem; } }

h2,
.like-h2 {
  font-family: "Open Sans", "Arial", sans-serif;
  font-weight: 700;
  font-style: normal;
  font-size: 1.6rem;
  line-height: 1.3;
  color: inherit; }
  @media all and (-ms-high-contrast: none) {
    h2,
    .like-h2 {
      font-weight: bold; } }
  @media only screen and (max-width: 767px) {
    h2,
    .like-h2 {
      font-size: 1.5rem; } }

h3,
.like-h3 {
  font-family: "Open Sans", "Arial", sans-serif;
  font-weight: 700;
  font-style: normal;
  font-size: 1.2rem;
  line-height: 1.3;
  color: inherit; }
  @media all and (-ms-high-contrast: none) {
    h3,
    .like-h3 {
      font-weight: bold; } }
  @media only screen and (max-width: 767px) {
    h3,
    .like-h3 {
      font-size: 1.25rem; } }

h4,
.like-h4 {
  font-family: "Open Sans", "Arial", sans-serif;
  font-weight: 700;
  font-style: normal;
  font-size: 1.067rem;
  line-height: 1.3;
  color: inherit; }
  @media all and (-ms-high-contrast: none) {
    h4,
    .like-h4 {
      font-weight: bold; } }
  @media only screen and (max-width: 767px) {
    h4,
    .like-h4 {
      font-size: 1rem; } }

p {
  font-family: "Open Sans", "Arial", sans-serif;
  font-weight: 400;
  font-style: normal;
  color: inherit;
  line-height: 24px; }

strong {
  font-family: "Open Sans", "Arial", sans-serif;
  font-weight: 600;
  font-style: normal; }

pre {
  padding: 2rem;
  background-color: #0b5560;
  color: #75ab30; }

h1 + h1 {
  margin-top: 1rem; }
  @media only screen and (max-width: 767px) {
    h1 + h1 {
      margin-top: 0.5rem; } }

h2 + h1 {
  margin-top: 1rem; }
  @media only screen and (max-width: 767px) {
    h2 + h1 {
      margin-top: 0.66667rem; } }

h3 + h1 {
  margin-top: 1rem; }
  @media only screen and (max-width: 767px) {
    h3 + h1 {
      margin-top: 0.66667rem; } }

h4 + h1 {
  margin-top: 1rem; }
  @media only screen and (max-width: 767px) {
    h4 + h1 {
      margin-top: 0.66667rem; } }

h5 + h1 {
  margin-top: 1rem; }
  @media only screen and (max-width: 767px) {
    h5 + h1 {
      margin-top: 0.66667rem; } }

p + h1 {
  margin-top: 1rem; }
  @media only screen and (max-width: 767px) {
    p + h1 {
      margin-top: 0.66667rem; } }

ol + h1 {
  margin-top: 1rem; }
  @media only screen and (max-width: 767px) {
    ol + h1 {
      margin-top: 0.66667rem; } }

ul + h1 {
  margin-top: 1rem; }
  @media only screen and (max-width: 767px) {
    ul + h1 {
      margin-top: 0.66667rem; } }

h1 + h2 {
  margin-top: 1rem; }
  @media only screen and (max-width: 767px) {
    h1 + h2 {
      margin-top: 0.5rem; } }

h2 + h2 {
  margin-top: 1rem; }
  @media only screen and (max-width: 767px) {
    h2 + h2 {
      margin-top: 0.66667rem; } }

h3 + h2 {
  margin-top: 1rem; }
  @media only screen and (max-width: 767px) {
    h3 + h2 {
      margin-top: 0.66667rem; } }

h4 + h2 {
  margin-top: 1rem; }
  @media only screen and (max-width: 767px) {
    h4 + h2 {
      margin-top: 0.66667rem; } }

h5 + h2 {
  margin-top: 1rem; }
  @media only screen and (max-width: 767px) {
    h5 + h2 {
      margin-top: 0.66667rem; } }

p + h2 {
  margin-top: 1rem; }
  @media only screen and (max-width: 767px) {
    p + h2 {
      margin-top: 0.66667rem; } }

ol + h2 {
  margin-top: 1rem; }
  @media only screen and (max-width: 767px) {
    ol + h2 {
      margin-top: 0.66667rem; } }

ul + h2 {
  margin-top: 1rem; }
  @media only screen and (max-width: 767px) {
    ul + h2 {
      margin-top: 0.66667rem; } }

h1 + h3 {
  margin-top: 1rem; }
  @media only screen and (max-width: 767px) {
    h1 + h3 {
      margin-top: 0.5rem; } }

h2 + h3 {
  margin-top: 1rem; }
  @media only screen and (max-width: 767px) {
    h2 + h3 {
      margin-top: 0.66667rem; } }

h3 + h3 {
  margin-top: 1rem; }
  @media only screen and (max-width: 767px) {
    h3 + h3 {
      margin-top: 0.66667rem; } }

h4 + h3 {
  margin-top: 1rem; }
  @media only screen and (max-width: 767px) {
    h4 + h3 {
      margin-top: 0.66667rem; } }

h5 + h3 {
  margin-top: 1rem; }
  @media only screen and (max-width: 767px) {
    h5 + h3 {
      margin-top: 0.66667rem; } }

p + h3 {
  margin-top: 1rem; }
  @media only screen and (max-width: 767px) {
    p + h3 {
      margin-top: 0.66667rem; } }

ol + h3 {
  margin-top: 1rem; }
  @media only screen and (max-width: 767px) {
    ol + h3 {
      margin-top: 0.66667rem; } }

ul + h3 {
  margin-top: 1rem; }
  @media only screen and (max-width: 767px) {
    ul + h3 {
      margin-top: 0.66667rem; } }

h1 + h4 {
  margin-top: 1rem; }
  @media only screen and (max-width: 767px) {
    h1 + h4 {
      margin-top: 0.5rem; } }

h2 + h4 {
  margin-top: 1rem; }
  @media only screen and (max-width: 767px) {
    h2 + h4 {
      margin-top: 0.66667rem; } }

h3 + h4 {
  margin-top: 1rem; }
  @media only screen and (max-width: 767px) {
    h3 + h4 {
      margin-top: 0.66667rem; } }

h4 + h4 {
  margin-top: 1rem; }
  @media only screen and (max-width: 767px) {
    h4 + h4 {
      margin-top: 0.66667rem; } }

h5 + h4 {
  margin-top: 1rem; }
  @media only screen and (max-width: 767px) {
    h5 + h4 {
      margin-top: 0.66667rem; } }

p + h4 {
  margin-top: 1rem; }
  @media only screen and (max-width: 767px) {
    p + h4 {
      margin-top: 0.66667rem; } }

ol + h4 {
  margin-top: 1rem; }
  @media only screen and (max-width: 767px) {
    ol + h4 {
      margin-top: 0.66667rem; } }

ul + h4 {
  margin-top: 1rem; }
  @media only screen and (max-width: 767px) {
    ul + h4 {
      margin-top: 0.66667rem; } }

h1 + h5 {
  margin-top: 1rem; }
  @media only screen and (max-width: 767px) {
    h1 + h5 {
      margin-top: 0.5rem; } }

h2 + h5 {
  margin-top: 1rem; }
  @media only screen and (max-width: 767px) {
    h2 + h5 {
      margin-top: 0.66667rem; } }

h3 + h5 {
  margin-top: 1rem; }
  @media only screen and (max-width: 767px) {
    h3 + h5 {
      margin-top: 0.66667rem; } }

h4 + h5 {
  margin-top: 1rem; }
  @media only screen and (max-width: 767px) {
    h4 + h5 {
      margin-top: 0.66667rem; } }

h5 + h5 {
  margin-top: 1rem; }
  @media only screen and (max-width: 767px) {
    h5 + h5 {
      margin-top: 0.66667rem; } }

p + h5 {
  margin-top: 1rem; }
  @media only screen and (max-width: 767px) {
    p + h5 {
      margin-top: 0.66667rem; } }

ol + h5 {
  margin-top: 1rem; }
  @media only screen and (max-width: 767px) {
    ol + h5 {
      margin-top: 0.66667rem; } }

ul + h5 {
  margin-top: 1rem; }
  @media only screen and (max-width: 767px) {
    ul + h5 {
      margin-top: 0.66667rem; } }

h1 + p {
  margin-top: 1rem; }
  @media only screen and (max-width: 767px) {
    h1 + p {
      margin-top: 0.5rem; } }

h2 + p {
  margin-top: 1rem; }
  @media only screen and (max-width: 767px) {
    h2 + p {
      margin-top: 0.66667rem; } }

h3 + p {
  margin-top: 1rem; }
  @media only screen and (max-width: 767px) {
    h3 + p {
      margin-top: 0.66667rem; } }

h4 + p {
  margin-top: 1rem; }
  @media only screen and (max-width: 767px) {
    h4 + p {
      margin-top: 0.66667rem; } }

h5 + p {
  margin-top: 1rem; }
  @media only screen and (max-width: 767px) {
    h5 + p {
      margin-top: 0.66667rem; } }

p + p {
  margin-top: 1rem; }
  @media only screen and (max-width: 767px) {
    p + p {
      margin-top: 0.66667rem; } }

ol + p {
  margin-top: 1rem; }
  @media only screen and (max-width: 767px) {
    ol + p {
      margin-top: 0.66667rem; } }

ul + p {
  margin-top: 1rem; }
  @media only screen and (max-width: 767px) {
    ul + p {
      margin-top: 0.66667rem; } }

h1 + ol {
  margin-top: 1rem; }
  @media only screen and (max-width: 767px) {
    h1 + ol {
      margin-top: 0.5rem; } }

h2 + ol {
  margin-top: 1rem; }
  @media only screen and (max-width: 767px) {
    h2 + ol {
      margin-top: 0.66667rem; } }

h3 + ol {
  margin-top: 1rem; }
  @media only screen and (max-width: 767px) {
    h3 + ol {
      margin-top: 0.66667rem; } }

h4 + ol {
  margin-top: 1rem; }
  @media only screen and (max-width: 767px) {
    h4 + ol {
      margin-top: 0.66667rem; } }

h5 + ol {
  margin-top: 1rem; }
  @media only screen and (max-width: 767px) {
    h5 + ol {
      margin-top: 0.66667rem; } }

p + ol {
  margin-top: 1rem; }
  @media only screen and (max-width: 767px) {
    p + ol {
      margin-top: 0.66667rem; } }

ol + ol {
  margin-top: 1rem; }
  @media only screen and (max-width: 767px) {
    ol + ol {
      margin-top: 0.66667rem; } }

ul + ol {
  margin-top: 1rem; }
  @media only screen and (max-width: 767px) {
    ul + ol {
      margin-top: 0.66667rem; } }

h1 + ul {
  margin-top: 1rem; }
  @media only screen and (max-width: 767px) {
    h1 + ul {
      margin-top: 0.5rem; } }

h2 + ul {
  margin-top: 1rem; }
  @media only screen and (max-width: 767px) {
    h2 + ul {
      margin-top: 0.66667rem; } }

h3 + ul {
  margin-top: 1rem; }
  @media only screen and (max-width: 767px) {
    h3 + ul {
      margin-top: 0.66667rem; } }

h4 + ul {
  margin-top: 1rem; }
  @media only screen and (max-width: 767px) {
    h4 + ul {
      margin-top: 0.66667rem; } }

h5 + ul {
  margin-top: 1rem; }
  @media only screen and (max-width: 767px) {
    h5 + ul {
      margin-top: 0.66667rem; } }

p + ul {
  margin-top: 1rem; }
  @media only screen and (max-width: 767px) {
    p + ul {
      margin-top: 0.66667rem; } }

ol + ul {
  margin-top: 1rem; }
  @media only screen and (max-width: 767px) {
    ol + ul {
      margin-top: 0.66667rem; } }

ul + ul {
  margin-top: 1rem; }
  @media only screen and (max-width: 767px) {
    ul + ul {
      margin-top: 0.66667rem; } }

a,
.like-a {
  font-family: "Open Sans", "Arial", sans-serif;
  font-weight: 700;
  font-style: normal;
  color: #0b5560;
  text-decoration: none; }
  @media all and (-ms-high-contrast: none) {
    a,
    .like-a {
      font-weight: bold; } }
  a:hover,
  .like-a:hover {
    color: #808285; }

.like-a-negative {
  font-family: "Open Sans", "Arial", sans-serif;
  font-weight: 700;
  font-style: normal;
  color: #0b5560;
  text-decoration: none;
  color: #fff; }
  @media all and (-ms-high-contrast: none) {
    .like-a-negative {
      font-weight: bold; } }
  .like-a-negative:hover {
    color: #808285; }
  .like-a-negative:hover {
    color: #f0f0f0; }

::selection {
  background: #4d4d4d;
  color: #808285; }

.content .component {
  opacity: 0;
  transition: opacity 1s; }
  .content .component.hide {
    display: none; }

.footer {
  opacity: 0;
  transition: opacity 1s; }

.content .component.component-ready.animate,
.footer.animate {
  opacity: 1; }

.content .component.component-failed {
  display: none !important; }

ul,
ol {
  list-style-position: inside; }
  ul li,
  ol li {
    line-height: 1.4;
    text-indent: 0;
    padding-left: 1rem; }
    ul li + li,
    ol li + li {
      margin-top: 0.5rem; }

@media only screen and (max-width: 767px) {
  .hide-on-mobile {
    display: none; } }

.clearfix {
  clear: both; }
  .clearfix:before, .clearfix:after {
    content: '';
    display: table; }

.bg-color-1 {
  background-color: #4d4d4d; }

.text-color-1 {
  color: #4d4d4d; }

.bg-color-2 {
  background-color: #f7941d; }

.text-color-2 {
  color: #f7941d; }

.bg-color-3 {
  background-color: #0b5560; }

.text-color-3 {
  color: #0b5560; }

.bg-color-4 {
  background-color: #808285; }

.text-color-4 {
  color: #808285; }

.bg-color-5 {
  background-color: #f0f0f0; }

.text-color-5 {
  color: #f0f0f0; }

.bg-color-6 {
  background-color: #fff; }

.text-color-6 {
  color: #fff; }

.bg-color-7 {
  background-color: #75ab30; }

.text-color-7 {
  color: #75ab30; }

.bg-color-8 {
  background-color: #f7bd00; }

.text-color-8 {
  color: #f7bd00; }

.bg-color-9 {
  background-color: #f74407; }

.text-color-9 {
  color: #f74407; }

.negative-elements a,
.negative-elements .like-a {
  font-family: "Open Sans", "Arial", sans-serif;
  font-weight: 700;
  font-style: normal;
  color: #0b5560;
  text-decoration: none;
  color: #fff; }
  @media all and (-ms-high-contrast: none) {
    .negative-elements a,
    .negative-elements .like-a {
      font-weight: bold; } }
  .negative-elements a:hover,
  .negative-elements .like-a:hover {
    color: #808285; }
  .negative-elements a:hover,
  .negative-elements .like-a:hover {
    color: #f0f0f0; }

.negative-elements input[type='submit'],
.negative-elements button,
.negative-elements .like-button {
  padding-left: 2rem;
  padding-right: 2rem;
  text-decoration: none;
  background-color: transparent;
  text-align: center;
  transition: all 0.2s;
  -webkit-appearance: none; }
  .negative-elements input[type='submit']:not(.disabled),
  .negative-elements button:not(.disabled),
  .negative-elements .like-button:not(.disabled) {
    cursor: pointer; }
  .negative-elements input[type='submit'] a,
  .negative-elements button a,
  .negative-elements .like-button a {
    color: #fff;
    text-decoration: none;
    background-color: transparent; }
  .negative-elements input[type='submit']:not(.disabled):hover,
  .negative-elements button:not(.disabled):hover,
  .negative-elements .like-button:not(.disabled):hover {
    color: #fff;
    background-color: #4d4d4d;
    text-shadow: none; }
    .negative-elements input[type='submit']:not(.disabled):hover a,
    .negative-elements button:not(.disabled):hover a,
    .negative-elements .like-button:not(.disabled):hover a {
      text-decoration: none;
      color: #fff;
      text-shadow: none; }

.negative-elements input:not([type='radio']):not([type='checkbox']):not([type='submit']),
.negative-elements textarea,
.negative-elements select {
  color: #fff;
  border-color: #f7941d; }

.component.container > .component-content {
  padding: 2rem;
  width: 100%;
  margin: 0 auto; }
  @media (min-width: 1200px) {
    .component.container > .component-content {
      position: relative;
      width: 90.25rem;
      max-width: 1140px; } }
  .component.container > .component-content .component-content {
    padding: 0; }

.bgcolor-gray,
.component-bgcolor-gray.component,
.content-bgcolor-gray > .component-content {
  background-color: whitesmoke; }

.bgcolor-blue,
.component-bgcolor-blue.component,
.content-bgcolor-blue > .component-content {
  background-color: #1890ba; }

.bgcolor-orange,
.component-bgcolor-orange.component,
.content-bgcolor-orange > .component-content {
  background-color: #f7941d; }

.text-color-dark .component-content {
  color: #333; }

.text-color-light .component-content {
  color: #ffffff; }

.text-color-orange .compnent-content {
  color: #f7941d; }

.versant-bgcolor-green {
  background-color: #0b5560 !important; }

.versant-rich-text h1,
.versant-rich-text h2,
.versant-rich-text h3,
.versant-rich-text h4,
.versant-rich-text h5,
.versant-rich-text h6 {
  color: #0b5560; }

.superiorvision-bgcolor--green {
  background-color: #0b5560 !important; }

.superiorvision-bgcolor--gray {
  background-color: #565151 !important; }

.superiorvision-bgcolor--gray-dark {
  background-color: #565151 !important; }

.superiorvision-bgcolor--gray-light {
  background-color: #e1e1e1 !important; }

.superiorvision-bgcolor--gray-superlight {
  background-color: #f7f7f7 !important; }

.superiorvision-bgcolor--white {
  background-color: #fff !important; }

.superiorvision-bgcolor--black {
  background-color: #565151 !important; }

.superiorvision-textcolor--black {
  color: #565151 !important; }

.superiorvision-textcolor--green {
  color: #0b5560 !important; }

.superiorvision-textcolor--gray {
  color: #565151 !important; }

.superiorvision-textcolor--gray-dark {
  color: #565151 !important; }

.superiorvision-textcolor--gray-light {
  color: #e1e1e1 !important; }

.superiorvision-textcolor--gray-superlight {
  color: #f7f7f7 !important; }

.superiorvision-textcolor--white {
  color: #fff !important; }

.header {
  opacity: 0; }
  .header.animate {
    opacity: 1; }
  .header .desktop-section {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1000; }
    .header .desktop-section > .inner-container {
      background-color: #f0f0f0; }
    .header .desktop-section::after {
      content: "";
      display: block;
      position: relative;
      width: 100%;
      height: 1.1rem;
      background-color: transparent;
      top: -1.2rem;
      box-shadow: 0 0.3rem 0.4rem -0.4rem rgba(77, 77, 77, 0.2); }
    .header .desktop-section .header-desktop-top > .inner-container {
      padding: 2rem;
      width: 100%;
      margin: 0 auto; }
      @media (min-width: 1200px) {
        .header .desktop-section .header-desktop-top > .inner-container {
          position: relative;
          width: 90.25rem;
          max-width: 1140px; } }
      .header .desktop-section .header-desktop-top > .inner-container .row-1 .component.image img {
        float: left;
        width: 165px;
        margin: 0; }
      .header .desktop-section .header-desktop-top > .inner-container .row-1 .component.search-field-spot {
        float: right;
        margin: 1rem; }
      .header .desktop-section .header-desktop-top > .inner-container .row-1 .component.navigation {
        float: right;
        margin: 1rem; }
      .header .desktop-section .header-desktop-top > .inner-container .row-1::after {
        content: "";
        display: block;
        clear: both; }
  .header .mobile-section .header-mobile-top {
    position: fixed;
    z-index: 100;
    background-color: #808285;
    width: 100%; }
    .header .mobile-section .header-mobile-top .inner-container::after {
      content: "";
      display: block;
      clear: both; }
    .header .mobile-section .header-mobile-top .image {
      width: 50%;
      padding: 1rem;
      float: left; }
    .header .mobile-section .header-mobile-top .nav-expander {
      float: right;
      padding: 3% 3% 0 0; }
      .header .mobile-section .header-mobile-top .nav-expander.nav-expanded > span:first-child {
        transform: rotate(-45deg) translate(-5px, 5px); }
      .header .mobile-section .header-mobile-top .nav-expander.nav-expanded > span:nth-child(2) {
        transform: scaleX(0); }
      .header .mobile-section .header-mobile-top .nav-expander.nav-expanded > span:nth-child(3) {
        transform: rotate(45deg) translate(-5px, -5px); }
      .header .mobile-section .header-mobile-top .nav-expander span {
        user-select: none;
        transition: transform 0.3s ease-in-out;
        background-color: #75ab30;
        width: 27px;
        height: 2px;
        display: block;
        margin: 5px 0; }
  .header .mobile-section .nav-back {
    position: fixed;
    visibility: hidden;
    opacity: 0;
    cursor: pointer;
    font-family: "Open Sans", "Arial", sans-serif;
    font-weight: 700;
    font-style: normal;
    font-size: 1.6rem;
    line-height: 1.3;
    color: inherit;
    z-index: 20;
    color: #75ab30;
    padding-left: 2rem;
    padding-top: 0.2rem;
    left: 0;
    right: 0;
    transition: margin 0.3s 0.2s;
    margin-top: -50px; }
    @media all and (-ms-high-contrast: none) {
      .header .mobile-section .nav-back {
        font-weight: bold; } }
    @media only screen and (max-width: 767px) {
      .header .mobile-section .nav-back {
        font-size: 1.5rem; } }
    .header .mobile-section .nav-back::before {
      content: "<";
      display: inline-block;
      padding-right: 0.5rem; }
    .header .mobile-section .nav-back.show-btn {
      visibility: visible;
      opacity: 1;
      margin-top: 0; }

.header ul.dgs-mega-menu {
  text-align: right;
  display: block;
  padding: 0; }
  .header ul.dgs-mega-menu > li:first-child {
    padding-left: 0; }
  .header ul.dgs-mega-menu > li:last-child {
    padding-right: 0; }
  .header ul.dgs-mega-menu * {
    text-align: left; }
  .header ul.dgs-mega-menu ul {
    font-size: 0; }
  .header ul.dgs-mega-menu li {
    margin-top: 0;
    line-height: normal;
    text-indent: 0;
    padding-left: 0; }
  .header ul.dgs-mega-menu a {
    color: #0b5560;
    font-size: 1rem; }
    .header ul.dgs-mega-menu a:hover {
      color: #808285; }
    .header ul.dgs-mega-menu a.active {
      color: #808285; }
      .header ul.dgs-mega-menu a.active::before {
        display: none; }
    .header ul.dgs-mega-menu a.current {
      color: #808285; }
      .header ul.dgs-mega-menu a.current::before {
        display: none; }
  .header ul.dgs-mega-menu > li > a.active:before {
    background-color: #808285; }
  .header ul.dgs-mega-menu > li > ul {
    background-color: #f0f0f0;
    padding: 1rem 0;
    text-align: center;
    box-shadow: 0 0.3rem 0.4rem -0.4rem rgba(77, 77, 77, 0.2); }
    .header ul.dgs-mega-menu > li > ul::before {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      left: -100%;
      bottom: 0;
      right: 99%;
      background-color: #f0f0f0;
      box-shadow: 0 0.3rem 0.4rem -0.4rem rgba(77, 77, 77, 0.2); }
    .header ul.dgs-mega-menu > li > ul::after {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      left: 99%;
      bottom: 0;
      right: -100%;
      background-color: #f0f0f0;
      box-shadow: 0 0.3rem 0.4rem -0.4rem rgba(77, 77, 77, 0.2); }
    .header ul.dgs-mega-menu > li > ul > li {
      border-right: none;
      width: auto;
      padding: 0 1rem 0 0;
      display: inline-block;
      position: relative;
      vertical-align: top; }
    .header ul.dgs-mega-menu > li > ul a {
      padding: 0; }

.header .dgs-burger-menu {
  position: fixed;
  top: 0;
  bottom: 0;
  z-index: 10;
  padding: 0;
  background-color: #808285; }
  .header .dgs-burger-menu li {
    padding: 1rem 2rem;
    margin-top: 0;
    line-height: normal;
    text-indent: 0;
    font-family: "Open Sans", "Arial", sans-serif;
    font-weight: 700;
    font-style: normal;
    font-size: 1.6rem;
    line-height: 1.3;
    color: inherit;
    border-bottom: thin solid #f7941d;
    background-color: #808285; }
    @media all and (-ms-high-contrast: none) {
      .header .dgs-burger-menu li {
        font-weight: bold; } }
    @media only screen and (max-width: 767px) {
      .header .dgs-burger-menu li {
        font-size: 1.5rem; } }
    .header .dgs-burger-menu li:nth-child(even) {
      background-color: #808285; }
    .header .dgs-burger-menu li:not(.no-dropdown):not(.sub-burger-title):not(.last-navigation) > a::after {
      content: ">";
      float: right; }
    .header .dgs-burger-menu li.sub-burger-title {
      text-align: center;
      font-size: 2.2rem; }
    .header .dgs-burger-menu li.last-navigation,
    .header .dgs-burger-menu li.last-navigation ul,
    .header .dgs-burger-menu li.last-navigation li {
      background-color: #f7941d; }
    .header .dgs-burger-menu li.last-navigation > a {
      padding-bottom: 1rem;
      border-bottom: 1px #808285 solid; }
    .header .dgs-burger-menu li.last-navigation ul {
      list-style: none;
      padding-top: 1rem; }
    .header .dgs-burger-menu li.last-navigation li {
      padding-left: 1rem; }
    .header .dgs-burger-menu li > a {
      width: 100%;
      color: #ffffff; }
    .header .dgs-burger-menu li ul {
      padding: 3rem 0 0 0;
      top: 0;
      bottom: 0;
      background-color: #808285; }
  .header .dgs-burger-menu a {
    padding: 0;
    margin: 0; }

.nice-select {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  background-color: #fff;
  border-radius: 5px;
  border: solid 1px #e8e8e8;
  box-sizing: border-box;
  clear: both;
  cursor: pointer;
  display: block;
  float: left;
  font-family: inherit;
  font-size: 16px;
  font-weight: normal;
  height: 42px;
  line-height: 40px;
  outline: none;
  padding-left: 18px;
  padding-right: 30px;
  position: relative;
  text-align: left !important;
  transition: all 0.2s ease-in-out;
  user-select: none;
  white-space: nowrap;
  width: auto; }
  .nice-select:hover {
    border-color: #dbdbdb; }
  .nice-select:active, .nice-select.open, .nice-select:focus {
    border-color: #999; }
  .nice-select:after {
    border-bottom: 2px solid #999;
    border-right: 2px solid #999;
    content: '';
    display: block;
    height: 5px;
    margin-top: -4px;
    pointer-events: none;
    position: absolute;
    right: 12px;
    top: 50%;
    transform-origin: 66% 66%;
    transform: rotate(45deg);
    transition: all 0.15s ease-in-out;
    width: 5px; }
  .nice-select.open:after {
    transform: rotate(-135deg); }
  .nice-select.open .list {
    opacity: 1;
    pointer-events: auto;
    transform: scale(1) translateY(0); }
  .nice-select.disabled {
    border-color: #ededed;
    color: #999;
    pointer-events: none; }
    .nice-select.disabled:after {
      border-color: #cccccc; }
  .nice-select.wide {
    width: 100%; }
    .nice-select.wide .list {
      left: 0 !important;
      right: 0 !important; }
  .nice-select.right {
    float: right; }
    .nice-select.right .list {
      left: auto;
      right: 0; }
  .nice-select.small {
    font-size: 0.8571rem;
    height: 36px;
    line-height: 34px; }
    .nice-select.small:after {
      height: 4px;
      width: 4px; }
    .nice-select.small .option {
      line-height: 34px;
      min-height: 34px; }
  .nice-select .list {
    background-color: #fff;
    border-radius: 5px;
    box-shadow: 0 0 0 1px rgba(68, 68, 68, 0.11);
    box-sizing: border-box;
    margin-top: 4px;
    opacity: 0;
    overflow: hidden;
    padding: 0;
    pointer-events: none;
    position: absolute;
    top: 100%;
    left: 0;
    transform-origin: 50% 0;
    transform: scale(0.75) translateY(-21px);
    transition: all 0.2s cubic-bezier(0.5, 0, 0, 1.25), opacity 0.15s ease-out;
    z-index: 9; }
    .nice-select .list:hover .option:not(:hover) {
      background-color: transparent !important; }
  .nice-select .option {
    cursor: pointer;
    font-weight: 400;
    line-height: 40px;
    list-style: none;
    min-height: 40px;
    outline: none;
    padding-left: 18px;
    padding-right: 29px;
    text-align: left;
    transition: all 0.2s; }
    .nice-select .option:hover, .nice-select .option.focus, .nice-select .option.selected.focus {
      background-color: #f6f6f6; }
    .nice-select .option.selected {
      font-weight: bold; }
    .nice-select .option.disabled {
      background-color: transparent;
      color: #999;
      cursor: default; }

.no-csspointerevents .nice-select .list {
  display: none; }

.no-csspointerevents .nice-select.open .list {
  display: block; }

.nice-select {
  display: inline-block;
  font-size: 1rem;
  width: auto;
  padding-top: 0.8rem;
  padding-bottom: 1rem;
  padding-left: 1rem;
  padding-right: 1rem;
  border: 2px solid #f7941d;
  border-radius: 4px;
  font-family: "Open Sans", "Arial", sans-serif;
  font-weight: normal;
  font-style: normal;
  color: #4d4d4d;
  line-height: 1;
  border-radius: 0;
  height: 3.35rem;
  float: none; }
  .nice-select:active, .nice-select.open, .nice-select.open, .nice-select:focus, .nice-select:hover {
    border-color: #f7941d; }
  .nice-select > .current {
    display: inline-block;
    line-height: 1.5; }
  .nice-select:after {
    border-bottom: 2px solid #f7941d;
    border-right: 2px solid #f7941d; }
  .nice-select > .list {
    width: 100%;
    border-radius: 0;
    box-shadow: 0 0 0 2px #f7941d;
    margin-top: 2px; }
    .nice-select > .list .option {
      padding-left: 1rem;
      padding-right: 1rem;
      padding-top: 0.8rem;
      padding-bottom: 1rem;
      line-height: 1;
      text-indent: 0; }
  .resource-center .nice-select {
    min-width: 13.8rem; }

#wrapper .header .desktop-section {
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  position: relative; }
  #wrapper .header .desktop-section > .inner-container {
    background-color: #ffffff; }
    #wrapper .header .desktop-section > .inner-container .header-desktop-topbar .microsite-header {
      color: #ffffff; }
      #wrapper .header .desktop-section > .inner-container .header-desktop-topbar .microsite-header .component-content {
        padding: 2rem;
        width: 100%;
        margin: 0 auto;
        padding: 1rem 0;
        text-align: center; }
        @media (min-width: 1200px) {
          #wrapper .header .desktop-section > .inner-container .header-desktop-topbar .microsite-header .component-content {
            position: relative;
            width: 90.25rem;
            max-width: 1140px; } }
        @media (min-width: 768px) {
          #wrapper .header .desktop-section > .inner-container .header-desktop-topbar .microsite-header .component-content {
            text-align: right;
            padding: 0.5rem 2rem; } }
        #wrapper .header .desktop-section > .inner-container .header-desktop-topbar .microsite-header .component-content p {
          font-weight: bold;
          font-size: 1.067rem; }
    #wrapper .header .desktop-section > .inner-container .header-desktop-topbar .contact-bar .component-content {
      margin: 0 auto;
      width: 100%; }
      @media (min-width: 1200px) {
        #wrapper .header .desktop-section > .inner-container .header-desktop-topbar .contact-bar .component-content {
          position: relative;
          width: 77rem;
          max-width: 1140px; } }
    #wrapper .header .desktop-section > .inner-container .header-desktop.outer-container > .inner-container {
      padding: 2rem;
      width: 100%;
      margin: 0 auto;
      padding-top: 0;
      padding-bottom: 0; }
      @media (min-width: 1200px) {
        #wrapper .header .desktop-section > .inner-container .header-desktop.outer-container > .inner-container {
          position: relative;
          width: 90.25rem;
          max-width: 1140px; } }
      #wrapper .header .desktop-section > .inner-container .header-desktop.outer-container > .inner-container .row-1.outer-container .inner-container {
        display: flex;
        align-items: center;
        justify-content: space-between; }
        #wrapper .header .desktop-section > .inner-container .header-desktop.outer-container > .inner-container .row-1.outer-container .inner-container .image .component-content a {
          display: block; }
          #wrapper .header .desktop-section > .inner-container .header-desktop.outer-container > .inner-container .row-1.outer-container .inner-container .image .component-content a img {
            height: auto;
            width: 110px; }
            @media (min-width: 768px) {
              #wrapper .header .desktop-section > .inner-container .header-desktop.outer-container > .inner-container .row-1.outer-container .inner-container .image .component-content a img {
                width: 145px; } }
        #wrapper .header .desktop-section > .inner-container .header-desktop.outer-container > .inner-container .row-1.outer-container .inner-container .logo-left img {
          width: 110px; }
          @media (min-width: 768px) {
            #wrapper .header .desktop-section > .inner-container .header-desktop.outer-container > .inner-container .row-1.outer-container .inner-container .logo-left img {
              padding-top: 14px;
              width: 200px; } }
        #wrapper .header .desktop-section > .inner-container .header-desktop.outer-container > .inner-container .row-1.outer-container .inner-container .navigation ul.dgs-mega-menu {
          display: flex; }
          #wrapper .header .desktop-section > .inner-container .header-desktop.outer-container > .inner-container .row-1.outer-container .inner-container .navigation ul.dgs-mega-menu li {
            padding: 0 1rem; }
            @media (min-width: 992px) {
              #wrapper .header .desktop-section > .inner-container .header-desktop.outer-container > .inner-container .row-1.outer-container .inner-container .navigation ul.dgs-mega-menu li {
                padding: 0 1.5rem; } }
            #wrapper .header .desktop-section > .inner-container .header-desktop.outer-container > .inner-container .row-1.outer-container .inner-container .navigation ul.dgs-mega-menu li a {
              color: #4d4d4d;
              font-family: "Open Sans", "Arial", sans-serif;
              font-weight: 600;
              transition: all 0.15s ease;
              white-space: nowrap;
              -webkit-font-smoothing: antialiased;
              font-size: 15px; }
              @media (min-width: 992px) {
                #wrapper .header .desktop-section > .inner-container .header-desktop.outer-container > .inner-container .row-1.outer-container .inner-container .navigation ul.dgs-mega-menu li a {
                  font-size: 18px;
                  padding: 0; } }
        @media (min-width: 768px) {
          #wrapper .header .desktop-section > .inner-container .header-desktop.outer-container > .inner-container .row-1.outer-container .inner-container .logo-right img {
            padding-top: 8px; } }
  #wrapper .header .desktop-section::after {
    height: auto;
    top: 0;
    box-shadow: none; }

#wrapper .header .mobile-section .header-mobile-topbar {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100vw;
  z-index: 9999; }
  #wrapper .header .mobile-section .header-mobile-topbar .rich-text-list.contact-bar .component-content ul {
    height: auto;
    font-size: 13px; }
    #wrapper .header .mobile-section .header-mobile-topbar .rich-text-list.contact-bar .component-content ul li {
      width: 100%; }
      #wrapper .header .mobile-section .header-mobile-topbar .rich-text-list.contact-bar .component-content ul li a,
      #wrapper .header .mobile-section .header-mobile-topbar .rich-text-list.contact-bar .component-content ul li div {
        text-align: center;
        justify-content: center;
        padding: 2.6rem 0.5rem 0.75rem;
        position: relative;
        width: 100%; }
        #wrapper .header .mobile-section .header-mobile-topbar .rich-text-list.contact-bar .component-content ul li a::before,
        #wrapper .header .mobile-section .header-mobile-topbar .rich-text-list.contact-bar .component-content ul li div::before {
          top: 0;
          position: absolute;
          left: 50%;
          margin-top: 0.75rem;
          transform: translate(-50%); }

#wrapper .header .mobile-section .header-mobile {
  border-bottom: 2px solid #e8e8e9;
  padding: 2rem;
  width: 100%;
  margin: 0 auto;
  padding-top: 0.75rem;
  padding-bottom: 0.25rem; }
  @media (min-width: 1200px) {
    #wrapper .header .mobile-section .header-mobile {
      position: relative;
      width: 90.25rem;
      max-width: 1140px; } }
  #wrapper .header .mobile-section .header-mobile .inner-container {
    display: flex;
    align-items: center;
    justify-content: space-between; }
    #wrapper .header .mobile-section .header-mobile .inner-container .image img {
      width: 110px;
      height: auto; }
    #wrapper .header .mobile-section .header-mobile .inner-container .logo-left {
      margin-top: 3px; }
    #wrapper .header .mobile-section .header-mobile .inner-container .logo-right img {
      width: 100px; }
    #wrapper .header .mobile-section .header-mobile .inner-container .nav-expander {
      float: none; }
      #wrapper .header .mobile-section .header-mobile .inner-container .nav-expander.nav-expanded > span:first-child {
        transform: rotate(-45deg) translate(-5px, 5px); }
      #wrapper .header .mobile-section .header-mobile .inner-container .nav-expander.nav-expanded > span:nth-child(2) {
        transform: scaleX(0); }
      #wrapper .header .mobile-section .header-mobile .inner-container .nav-expander.nav-expanded > span:nth-child(3) {
        transform: rotate(45deg) translate(-5px, -5px); }
      #wrapper .header .mobile-section .header-mobile .inner-container .nav-expander span {
        user-select: none;
        transition: transform 0.3s ease-in-out;
        background-color: #0b5560;
        width: 27px;
        height: 2px;
        display: block;
        margin: 5px 0; }

.header .dgs-burger-menu {
  background-color: #0b5560; }
  .header .dgs-burger-menu li {
    padding: 1rem 2rem;
    border-bottom: #ffffff;
    background-color: #ffffff; }
    .header .dgs-burger-menu li:nth-child(even) {
      background-color: #ffffff; }
    .header .dgs-burger-menu li.last-navigation,
    .header .dgs-burger-menu li.last-navigation ul,
    .header .dgs-burger-menu li.last-navigation li {
      background-color: #f7941d; }
    .header .dgs-burger-menu li.last-navigation > a {
      border-bottom: 1px #808285 solid; }
    .header .dgs-burger-menu li > a {
      color: #0b5560; }
    .header .dgs-burger-menu li ul {
      background-color: #0b5560; }

#footer {
  padding-bottom: 66px; }
  @media (min-width: 768px) {
    #footer {
      padding-bottom: 0; } }

a.icomoon {
  font-family: "Open Sans", "Arial", sans-serif !important;
  font-weight: 600; }
  a.icomoon::before {
    font-family: "DGS-WebProgram" !important;
    font-weight: 400;
    font-size: 18px;
    text-indent: 0;
    transform: translate(15px); }
    @media (min-width: 992px) {
      a.icomoon::before {
        transform: translate(0); } }

a.icon-home:before {
  font-family: 'icomoon-yourhearing' !important;
  content: '\e914'; }

a.icomoon {
  font-family: "Open Sans", "Arial", sans-serif !important;
  font-weight: 600; }
  a.icomoon::before {
    font-weight: 400;
    font-size: 18px;
    text-indent: 0;
    transform: translate(15px); }
    @media (min-width: 992px) {
      a.icomoon::before {
        transform: translate(0); } }

input[type='submit'].button-orange,
button.button-orange,
.button-orange {
  background-color: #f7941d;
  border-radius: 4px;
  border: 0;
  border-bottom: 3px solid #d97a08;
  color: #ffffff;
  padding: 1rem 2rem;
  transition: background-color 0.3s ease; }
  input[type='submit'].button-orange:hover,
  button.button-orange:hover,
  .button-orange:hover {
    background-color: #f9ab4e; }
  input[type='submit'].button-orange:focus,
  button.button-orange:focus,
  .button-orange:focus {
    box-shadow: 0px 0px 6px 0px #1890ba; }
  input[type='submit'].button-orange:active, input.nice-select.open[type='submit'].button-orange,
  button.button-orange:active,
  button.button-orange.nice-select.open,
  .button-orange:active,
  .button-orange.nice-select.open {
    border-top: 3px solid #d97a08;
    border-bottom: 0;
    border-left: 3px solid #d97a08; }

input[type='submit'].button-blue,
button.button-blue,
.button-blue {
  background-color: #1890ba;
  border-radius: 4px;
  border: 0;
  border-bottom: 3px solid #126d8d;
  color: #ffffff;
  padding: 1rem 2rem;
  transition: background-color 0.3s ease; }
  input[type='submit'].button-blue:hover,
  button.button-blue:hover,
  .button-blue:hover {
    background-color: #22b1e3; }
  input[type='submit'].button-blue:focus,
  button.button-blue:focus,
  .button-blue:focus {
    box-shadow: 0px 0px 6px 0px #1890ba; }
  input[type='submit'].button-blue:active, input.nice-select.open[type='submit'].button-blue,
  button.button-blue:active,
  button.button-blue.nice-select.open,
  .button-blue:active,
  .button-blue.nice-select.open {
    border-top: 3px solid #126d8d;
    border-bottom: 0;
    border-left: 3px solid #126d8d; }

input[type='submit'].button-gray,
button.button-gray,
.button-gray {
  background-color: #808285;
  border-radius: 4px;
  border: 0;
  border-bottom: 3px solid #67696b;
  color: #ffffff;
  padding: 1rem 2rem;
  transition: background-color 0.3s ease; }
  input[type='submit'].button-gray:hover,
  button.button-gray:hover,
  .button-gray:hover {
    background-color: #9a9c9e; }
  input[type='submit'].button-gray:focus,
  button.button-gray:focus,
  .button-gray:focus {
    box-shadow: 0px 0px 6px 0px #1890ba; }
  input[type='submit'].button-gray:active, input.nice-select.open[type='submit'].button-gray,
  button.button-gray:active,
  button.button-gray.nice-select.open,
  .button-gray:active,
  .button-gray.nice-select.open {
    border-top: 3px solid #67696b;
    border-bottom: 0;
    border-left: 3px solid #67696b; }

input[type='submit'].button-outline-orange,
button.button-outline-orange,
.button-outline-orange {
  background-color: transparent;
  border-radius: 4px;
  border: 1px solid #cccccc;
  border-bottom: 3px solid #cccccc;
  color: #f7941d;
  padding: 1rem 2rem;
  transition: background-color 0.3s ease; }
  input[type='submit'].button-outline-orange:hover,
  button.button-outline-orange:hover,
  .button-outline-orange:hover {
    background-color: transparent;
    border-color: #999999;
    color: #c16c07; }
  input[type='submit'].button-outline-orange:focus,
  button.button-outline-orange:focus,
  .button-outline-orange:focus {
    box-shadow: 0px 0px 6px 0px #1890ba; }
  input[type='submit'].button-outline-orange:active, input.nice-select.open[type='submit'].button-outline-orange,
  button.button-outline-orange:active,
  button.button-outline-orange.nice-select.open,
  .button-outline-orange:active,
  .button-outline-orange.nice-select.open {
    background-color: #f2f2f2;
    border-top: 3px solid #cccccc;
    border-left: 3px solid #cccccc; }

input[type='submit'].button-outline-blue,
button.button-outline-blue,
.button-outline-blue {
  background-color: transparent;
  border-radius: 4px;
  border: 1px solid #cccccc;
  border-bottom: 3px solid #cccccc;
  color: #1890ba;
  padding: 1rem 2rem;
  transition: background-color 0.3s ease; }
  input[type='submit'].button-outline-blue:hover,
  button.button-outline-blue:hover,
  .button-outline-blue:hover {
    background-color: transparent;
    border-color: #999999;
    color: #0f5c76; }
  input[type='submit'].button-outline-blue:focus,
  button.button-outline-blue:focus,
  .button-outline-blue:focus {
    box-shadow: 0px 0px 6px 0px #1890ba; }
  input[type='submit'].button-outline-blue:active, input.nice-select.open[type='submit'].button-outline-blue,
  button.button-outline-blue:active,
  button.button-outline-blue.nice-select.open,
  .button-outline-blue:active,
  .button-outline-blue.nice-select.open {
    background-color: #f2f2f2;
    border-top: 3px solid #cccccc;
    border-left: 3px solid #cccccc; }

input[type='submit'].button-outline-gray,
button.button-outline-gray,
.button-outline-gray {
  background-color: transparent;
  border-radius: 4px;
  border: 1px solid #cccccc;
  border-bottom: 3px solid #cccccc;
  color: #808285;
  padding: 1rem 2rem;
  transition: background-color 0.3s ease; }
  input[type='submit'].button-outline-gray:hover,
  button.button-outline-gray:hover,
  .button-outline-gray:hover {
    background-color: transparent;
    border-color: #999999;
    color: #5a5c5e; }
  input[type='submit'].button-outline-gray:focus,
  button.button-outline-gray:focus,
  .button-outline-gray:focus {
    box-shadow: 0px 0px 6px 0px #1890ba; }
  input[type='submit'].button-outline-gray:active, input.nice-select.open[type='submit'].button-outline-gray,
  button.button-outline-gray:active,
  button.button-outline-gray.nice-select.open,
  .button-outline-gray:active,
  .button-outline-gray.nice-select.open {
    background-color: #f2f2f2;
    border-top: 3px solid #cccccc;
    border-left: 3px solid #cccccc; }

button,
.button,
.yh-button a,
.image-spot-element-link a {
  display: inline-block;
  background-color: #f7941d;
  color: #ffffff;
  border-radius: 4px;
  border: 0;
  border-bottom: 3px solid #d97a08;
  padding: 1rem 2rem;
  transition: background-color 0.3s ease;
  font-size: 1.2143rem;
  font-weight: 400;
  font-style: normal; }
  button:hover,
  .button:hover,
  .yh-button a:hover,
  .image-spot-element-link a:hover {
    background-color: #f9ab4e; }
  button:focus,
  .button:focus,
  .yh-button a:focus,
  .image-spot-element-link a:focus {
    box-shadow: 0px 0px 6px 0px #1890ba; }
  button:active, button.nice-select.open,
  .button:active,
  .button.nice-select.open,
  .yh-button a:active,
  .yh-button a.nice-select.open,
  .image-spot-element-link a:active,
  .image-spot-element-link a.nice-select.open {
    border-top: 3px solid #d97a08;
    border-bottom: 0;
    border-left: 3px solid #d97a08; }

.form .component-content {
  padding: 0 2rem; }
  @media (min-width: 992px) {
    .form .component-content {
      padding: 0 1rem 0 0 !important; } }
  .form .component-content .scfForm {
    background-color: #f5f5f5;
    box-shadow: 0 13px 27px -5px rgba(50, 50, 93, 0.25), 0 8px 16px -8px rgba(0, 0, 0, 0.3), 0 -6px 16px -6px rgba(0, 0, 0, 0.025);
    padding-bottom: 2rem;
    font-family: "Open Sans", "Arial", sans-serif; }
    .form .component-content .scfForm label {
      display: block;
      padding-bottom: 0.3125rem;
      color: #333;
      font-weight: bold; }
    .form .component-content .scfForm input:not([type='radio']):not([type='checkbox']):not([type='submit']),
    .form .component-content .scfForm textarea,
    .form .component-content .scfForm select {
      display: inline-block;
      font-size: 1rem;
      width: auto;
      padding-top: 0.8rem;
      padding-bottom: 1rem;
      padding-left: 1rem;
      padding-right: 1rem;
      border: 2px solid #a9a9a9;
      border-radius: 0;
      font-family: "Open Sans", "Arial", sans-serif;
      font-weight: normal;
      font-style: normal;
      color: #333;
      line-height: 1;
      background-color: #ffffff;
      width: 100%; }
      .form .component-content .scfForm input:not([type='radio']):not([type='checkbox']):not([type='submit']):focus,
      .form .component-content .scfForm textarea:focus,
      .form .component-content .scfForm select:focus {
        border-color: #f7941d; }
    .form .component-content .scfForm input[type='submit'],
    .form .component-content .scfForm button,
    .form .component-content .scfForm .like-button {
      background-color: #f7941d;
      border-radius: 4px;
      border: 0;
      border-bottom: 3px solid #d97a08;
      color: #ffffff;
      padding: 1rem 2rem;
      transition: background-color 0.3s ease;
      white-space: nowrap;
      box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
      border-radius: 0;
      font-size: 15px;
      font-weight: 600;
      text-transform: uppercase;
      text-decoration: none;
      letter-spacing: 0.05em;
      transition: all 0.15s ease;
      width: 100%; }
      .form .component-content .scfForm input[type='submit']:hover,
      .form .component-content .scfForm button:hover,
      .form .component-content .scfForm .like-button:hover {
        background-color: #f9ab4e; }
      .form .component-content .scfForm input[type='submit']:focus,
      .form .component-content .scfForm button:focus,
      .form .component-content .scfForm .like-button:focus {
        box-shadow: 0px 0px 6px 0px #1890ba; }
      .form .component-content .scfForm input[type='submit']:active, .form .component-content .scfForm input.nice-select.open[type='submit'],
      .form .component-content .scfForm button:active,
      .form .component-content .scfForm button.nice-select.open,
      .form .component-content .scfForm .like-button:active,
      .form .component-content .scfForm .like-button.nice-select.open {
        border-top: 3px solid #d97a08;
        border-bottom: 0;
        border-left: 3px solid #d97a08; }
    .form .component-content .scfForm .scfTitleBorder {
      margin: 0;
      background-color: #0b5560;
      color: #ffffff;
      text-align: center;
      padding: 2rem; }
    .form .component-content .scfForm .scfSectionContent {
      padding: 2rem;
      padding-bottom: 0; }
    .form .component-content .scfForm .scfDroplistBorder,
    .form .component-content .scfForm .scfEmailBorder,
    .form .component-content .scfForm .scfMultipleLineTextBorder,
    .form .component-content .scfForm .scfSingleLineTextBorder,
    .form .component-content .scfForm .scfPasswordBorder,
    .form .component-content .scfForm .scfNumberBorder,
    .form .component-content .scfForm .scfDatePickerBorder,
    .form .component-content .scfForm .scfDateBorder,
    .form .component-content .scfForm .scfRadioButtonListBorder,
    .form .component-content .scfForm .scfListBoxBorder,
    .form .component-content .scfForm .scfCheckBoxListBorder,
    .form .component-content .scfForm .scfFileUploadBorder,
    .form .component-content .scfForm .scfDateSelectorBorder,
    .form .component-content .scfForm .scfCreditCardBorder,
    .form .component-content .scfForm .scfConfirmPasswordBorder,
    .form .component-content .scfForm .scfCaptchaBorder,
    .form .component-content .scfForm .scfTelephoneBorder,
    .form .component-content .scfForm .scfSmsTelephoneBorder {
      margin-bottom: 0.83333rem;
      margin-top: 0;
      position: relative; }
    .form .component-content .scfForm .scfDropListLabel,
    .form .component-content .scfForm .scfEmailLabel,
    .form .component-content .scfForm .scfMultipleLineTextLabel,
    .form .component-content .scfForm .scfSingleLineTextLabel,
    .form .component-content .scfForm .scfPasswordLabel,
    .form .component-content .scfForm .scfNumberLabel,
    .form .component-content .scfForm .scfDatePickerLabel,
    .form .component-content .scfForm .scfDateLabel,
    .form .component-content .scfForm .scfRadioButtonListLabel,
    .form .component-content .scfForm .scfCheckBoxListLabel,
    .form .component-content .scfForm .scfListBoxLabel,
    .form .component-content .scfForm .scfFileUploadLabel,
    .form .component-content .scfForm .scfDateSelectorLabel,
    .form .component-content .scfForm .scfCreditCardLabel,
    .form .component-content .scfForm .scfConfirmPasswordLabel,
    .form .component-content .scfForm .scfCaptchaLabel,
    .form .component-content .scfForm .scfTelephoneLabel,
    .form .component-content .scfForm .scfSmsTelephoneLabel {
      float: none;
      clear: none;
      width: 100%;
      padding: 0 0 0.3125rem 0; }
    .form .component-content .scfForm .scfEmailGeneralPanel,
    .form .component-content .scfForm .scfMultipleLineGeneralPanel,
    .form .component-content .scfForm .scfSingleLineGeneralPanel,
    .form .component-content .scfForm .scfPasswordGeneralPanel,
    .form .component-content .scfForm .scfNumberGeneralPanel,
    .form .component-content .scfForm .scfDatePickerGeneralPanel,
    .form .component-content .scfForm .scfDateGeneralPanel,
    .form .component-content .scfForm .scfRadioButtonListGeneralPanel,
    .form .component-content .scfForm .scfCheckBoxListGeneralPanel,
    .form .component-content .scfForm .scfFileUploadGeneralPanel,
    .form .component-content .scfForm .scfDateSelectorGeneralPanel,
    .form .component-content .scfForm .scfCreditCardGeneralPanel,
    .form .component-content .scfForm .scfConfirmPasswordGeneralPanel,
    .form .component-content .scfForm .scfCaptchaGeneralPanel,
    .form .component-content .scfForm .scfTelephoneGeneralPanel,
    .form .component-content .scfForm .scfSmsTelephoneGeneralPanel {
      float: none;
      clear: none;
      width: 100%; }
    .form .component-content .scfForm .scfValidatorRequired,
    .form .component-content .scfForm .scfRequired {
      float: none;
      position: static;
      margin-left: 0.25rem;
      color: #f74407; }
    .form .component-content .scfForm .scfCheckboxBorder {
      position: relative;
      padding-top: 1rem; }
      .form .component-content .scfForm .scfCheckboxBorder .scfRequired {
        display: none; }
    .form .component-content .scfForm .scfSubmitButtonBorder {
      text-align: center;
      padding: 0 2rem; }
    .form .component-content .scfForm .scfListBoxGeneralPanel,
    .form .component-content .scfForm .scfDropListGeneralPanel {
      width: 100%;
      float: none; }
    .form .component-content .scfForm .scfError,
    .form .component-content .scfForm .scfValidationSummary,
    .form .component-content .scfForm .scfValidatorRequired,
    .form .component-content .scfForm .scfValidator {
      color: #f74407; }
    .form .component-content .scfForm .scfValidationSummary {
      padding: 1rem 2rem 0;
      margin-left: 0; }
    .form .component-content .scfForm .scfSectionBorderAsFieldSet {
      padding: 0;
      border: 0;
      margin: 0 0 0.625rem 0; }
    .form .component-content .scfForm .scfSectionLegend {
      font-family: "Open Sans", "Arial", sans-serif;
      font-weight: 700;
      font-style: normal;
      font-size: 1.2rem;
      line-height: 1.3;
      color: inherit;
      padding: 0;
      margin: 0;
      margin-bottom: 0.41667rem; }
      @media all and (-ms-high-contrast: none) {
        .form .component-content .scfForm .scfSectionLegend {
          font-weight: bold; } }
      @media only screen and (max-width: 767px) {
        .form .component-content .scfForm .scfSectionLegend {
          font-size: 1.25rem; } }
    .form .component-content .scfForm .scfHiddenFieldBorder {
      display: none; }
    .form .component-content .scfForm .scfIntroBorder {
      display: none; }
    .form .component-content .scfForm input[type='checkbox'] + label,
    .form .component-content .scfForm input[type='radio'] + label {
      margin-left: 0.3rem; }
  .form .component-content .scfCheckboxBorder {
    clear: both;
    position: relative;
    padding: 2rem 0 0; }
    .form .component-content .scfCheckboxBorder .scfValidatorRequired {
      display: none; }
  .form .component-content .scfCheckbox {
    display: flex;
    align-items: flex-start;
    width: 100%;
    padding-bottom: 1rem; }
    .form .component-content .scfCheckbox label {
      font-weight: normal;
      font-size: 16px; }
  .form .component-content .scfFooterBorder {
    padding: 1rem 2rem 0 2rem; }
    .form .component-content .scfFooterBorder p {
      font-style: italic;
      font-size: 14px; }
  .form .component-content .nice-select {
    border-color: #b4b5b7;
    border-width: 2px; }
    .form .component-content .nice-select:after {
      border-color: #b4b5b7; }

.component.intro-banner {
  padding-top: 0;
  padding-bottom: 0; }
  .component.intro-banner .component-content {
    position: relative; }
  .component.intro-banner .intro-banner-image {
    width: 100%; }
    .component.intro-banner .intro-banner-image img {
      max-width: 100%;
      height: auto;
      display: block;
      width: 100%; }
  .component.intro-banner .intro-banner-content {
    position: absolute;
    top: 2rem;
    bottom: 2rem;
    left: 0;
    right: 0; }
  .component.intro-banner .outer-pos-wrapper {
    padding: 2rem;
    width: 100%;
    margin: 0 auto;
    height: 100%; }
    @media (min-width: 1200px) {
      .component.intro-banner .outer-pos-wrapper {
        position: relative;
        width: 90.25rem;
        max-width: 1140px; } }
  .component.intro-banner .inner-pos-wrapper {
    width: 100%;
    position: relative;
    display: inline-block; }
    @media only screen and (min-width: 992px) {
      .component.intro-banner .inner-pos-wrapper {
        width: 55%; } }
  .component.intro-banner .intro-banner-link {
    display: none; }
  .component.intro-banner .sticky-downlink {
    position: absolute;
    bottom: 0;
    width: 100%;
    text-align: center; }
    .component.intro-banner .sticky-downlink a {
      display: inline-block;
      transition: all 0.2s;
      color: inherit; }
      .component.intro-banner .sticky-downlink a:before {
        font-family: "DGS-WebProgram";
        content: "";
        font-size: 4rem;
        line-height: 1;
        color: inherit; }
      .component.intro-banner .sticky-downlink a:hover {
        margin-bottom: -0.5rem; }
      .component.intro-banner .sticky-downlink a span {
        display: none; }
  .component.intro-banner.middle .inner-pos-wrapper {
    top: 50%;
    transform: translateY(-50%); }
  .component.intro-banner.bottom .inner-pos-wrapper {
    top: 100%;
    transform: translateY(-100%); }
  .component.intro-banner.right .outer-pos-wrapper {
    text-align: right; }
  .component.intro-banner.center .outer-pos-wrapper {
    text-align: center; }
  .component.intro-banner.content-overflow .intro-banner-content {
    position: static;
    padding-top: 1.25rem;
    padding-bottom: 1.25rem; }
    @media only screen and (max-width: 767px) {
      .component.intro-banner.content-overflow .intro-banner-content {
        padding-top: 0.625rem; } }
    @media only screen and (max-width: 767px) {
      .component.intro-banner.content-overflow .intro-banner-content {
        padding-bottom: 0.625rem; } }
  .component.intro-banner.content-overflow .inner-pos-wrapper {
    position: static;
    transform: none; }

.landing-page-intro-banner {
  background-color: #0b5560; }

.component.intro-banner {
  background-color: #0b5560; }
  .component.intro-banner .intro-banner-image {
    display: none; }
    @media (min-width: 1200px) {
      .component.intro-banner .intro-banner-image {
        display: block; } }
  .component.intro-banner .intro-banner-content {
    top: 0;
    bottom: 0; }
  .component.intro-banner .outer-pos-wrapper {
    display: flex;
    align-items: center; }
  @media (min-width: 1200px) {
    .component.intro-banner.content-overflow .intro-banner-content {
      position: absolute;
      top: 0;
      bottom: 0; } }
  @media (min-width: 1200px) {
    .component.intro-banner.content-overflow .intro-banner-content .outer-pos-wrapper {
      padding: 0 2rem; } }

.component.intro-banner-thanks .component-content .intro-banner-image img {
  filter: brightness(40%); }

.component.intro-banner-thanks .component-content .intro-banner-content p {
  font-size: 16px; }

.component.rich-text .component-content {
  padding: 2rem;
  width: 100%;
  margin: 0 auto; }
  @media (min-width: 1200px) {
    .component.rich-text .component-content {
      position: relative;
      width: 90.25rem;
      max-width: 1140px; } }

.component.rich-text.slim .component-content {
  margin: 0 auto;
  width: 100%; }
  @media (min-width: 1200px) {
    .component.rich-text.slim .component-content {
      position: relative;
      width: 77rem;
      max-width: 1140px; } }

.component.rich-text.overlay {
  padding: 0 !important;
  opacity: 0 !important;
  position: relative;
  transition: opacity 0.1s ease-in; }
  .component.rich-text.overlay .component-content {
    transition: all 0.2s ease-in;
    position: absolute;
    top: 0;
    left: -25%;
    right: 100%;
    transform: translateY(-100%);
    padding: 0.625rem;
    background-color: black;
    color: #ffffff;
    margin: 0;
    width: auto;
    max-width: none; }
    .component.rich-text.overlay .component-content * {
      color: white; }
    .component.rich-text.overlay .component-content h1,
    .component.rich-text.overlay .component-content h2,
    .component.rich-text.overlay .component-content h3,
    .component.rich-text.overlay .component-content h4 {
      font-size: 1rem; }
    .component.rich-text.overlay .component-content p {
      font-size: 0.8rem; }
  .component.rich-text.overlay.active {
    opacity: 1 !important; }
    .component.rich-text.overlay.active .component-content {
      left: 0;
      right: 75%; }

.on-page-editor #wrapper > #content .component.rich-text {
  min-height: 100px; }

.rich-text.rich-text-article-width .component-content {
  max-width: 100%;
  width: 50rem; }
  @media (min-width: 768px) {
    .rich-text.rich-text-article-width .component-content {
      max-width: 90%; } }

.rich-text-list.contact-bar .component-content ul {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  list-style-type: none;
  height: 3.125rem;
  font-size: 18px; }
  .rich-text-list.contact-bar .component-content ul li {
    display: inline-flex;
    margin: 0;
    padding: 0; }
    .rich-text-list.contact-bar .component-content ul li:first-of-type a {
      border-left: none; }
    .rich-text-list.contact-bar .component-content ul li a,
    .rich-text-list.contact-bar .component-content ul li div {
      display: flex;
      align-items: center;
      padding: 0 1rem;
      border-left: 1px solid rgba(255, 255, 255, 0.5);
      color: #ffffff;
      text-decoration: none;
      font-weight: normal; }
      .rich-text-list.contact-bar .component-content ul li a::before,
      .rich-text-list.contact-bar .component-content ul li div::before {
        display: block;
        height: 1.25rem;
        width: 1.25rem;
        font-size: 18px;
        margin-right: 0.5rem; }
      .rich-text-list.contact-bar .component-content ul li a:hover,
      .rich-text-list.contact-bar .component-content ul li div:hover {
        background-color: rgba(255, 255, 255, 0.2); }

.rich-text-list.contact-bar.contact-bar-flex-center .component-content ul {
  justify-content: center; }
  .rich-text-list.contact-bar.contact-bar-flex-center .component-content ul li:first-of-type a {
    border-left: none; }

@media only screen and (min-width: 992px) {
  .component.two-section-grid.wide-thin-columns .component-content {
    padding: 2rem;
    width: 100%;
    margin: 0 auto; } }
  @media only screen and (min-width: 992px) and (min-width: 1200px) {
    .component.two-section-grid.wide-thin-columns .component-content {
      position: relative;
      width: 90.25rem;
      max-width: 1140px; } }

@media only screen and (min-width: 992px) {
  .component.two-section-grid.wide-thin-columns .section-one {
    width: 66.66%;
    float: left; }
  .component.two-section-grid.wide-thin-columns .section-two {
    width: 33.32%;
    float: left; }
  .component.two-section-grid.wide-thin-columns::after {
    content: "";
    display: block;
    clear: both; }
  .component.two-section-grid.thin-wide-columns .component-content {
    padding: 2rem;
    width: 100%;
    margin: 0 auto; } }
  @media only screen and (min-width: 992px) and (min-width: 1200px) {
    .component.two-section-grid.thin-wide-columns .component-content {
      position: relative;
      width: 90.25rem;
      max-width: 1140px; } }

@media only screen and (min-width: 992px) {
  .component.two-section-grid.thin-wide-columns .section-one {
    width: 33.32%;
    float: left; }
  .component.two-section-grid.thin-wide-columns .section-two {
    width: 66.66%;
    float: left; }
  .component.two-section-grid.fifty-fifty-columns > .component-content {
    padding: 2rem;
    width: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap; } }
  @media only screen and (min-width: 992px) and (min-width: 1200px) {
    .component.two-section-grid.fifty-fifty-columns > .component-content {
      position: relative;
      width: 90.25rem;
      max-width: 1140px; } }

@media only screen and (min-width: 992px) {
  .component.two-section-grid.fifty-fifty-columns .section-one {
    display: flex;
    flex-direction: column;
    flex-basis: 100%;
    flex: 1; }
    .component.two-section-grid.fifty-fifty-columns .section-one .component-content {
      width: 100%;
      max-width: 100%; }
  .component.two-section-grid.fifty-fifty-columns .section-two {
    display: flex;
    flex-direction: column;
    flex-basis: 100%;
    flex: 1; }
    .component.two-section-grid.fifty-fifty-columns .section-two .component-content {
      width: 100%;
      max-width: 100%; }
  .component.two-section-grid.as-four-section .section-one {
    width: 100%; }
  .component.two-section-grid.carousel-two-column .section-one {
    display: inline-block;
    width: 65.95744680851064%;
    vertical-align: top; }
  .component.two-section-grid.carousel-two-column .section-two {
    display: inline-block;
    width: 31.914893617021278%;
    padding-left: 2.127659574468085%;
    vertical-align: top; } }

@media only screen and (min-width: 1200px) {
  .component.two-section-grid.as-four-section .section-one {
    display: inline-block;
    width: 73%;
    vertical-align: top; }
  .component.two-section-grid.as-four-section .section-two {
    display: inline-block;
    width: 20%;
    padding-left: 2.127659574468085%;
    vertical-align: top;
    margin-left: 5%; } }

.component.two-section-grid .component-content .section-one .component > .component-content {
  width: 100%;
  max-width: 100%; }

.component.two-section-grid .component-content .section-two .component > .component-content {
  width: 100%;
  max-width: 100%; }

.component.two-section-grid.wide-thin-columns > .component-content {
  padding: 0 0 3rem; }
  @media (min-width: 992px) {
    .component.two-section-grid.wide-thin-columns > .component-content {
      padding: 2rem 0;
      display: flex; } }
  .component.two-section-grid.wide-thin-columns > .component-content .section-one {
    float: none !important;
    width: 100%; }
    @media (min-width: 992px) {
      .component.two-section-grid.wide-thin-columns > .component-content .section-one {
        width: 62.5% !important;
        padding-right: 2rem; } }
    .component.two-section-grid.wide-thin-columns > .component-content .section-one ul,
    .component.two-section-grid.wide-thin-columns > .component-content .section-one ol {
      list-style-position: inherit; }
      .component.two-section-grid.wide-thin-columns > .component-content .section-one ul li,
      .component.two-section-grid.wide-thin-columns > .component-content .section-one ol li {
        line-height: 1.4;
        text-indent: 0;
        margin-left: 1rem;
        padding-left: 0; }
  .component.two-section-grid.wide-thin-columns > .component-content .section-two {
    float: none !important;
    width: 100%; }
    @media (min-width: 992px) {
      .component.two-section-grid.wide-thin-columns > .component-content .section-two {
        width: 37.5% !important; } }
    @media (min-width: 992px) {
      .component.two-section-grid.wide-thin-columns > .component-content .section-two .two-column-grid-form {
        padding: 0; } }

.component.two-section-grid-anchorlinks {
  height: 100%; }
  .component.two-section-grid-anchorlinks > .component-content {
    padding: 2rem;
    width: 100%;
    margin: 0 auto;
    position: relative;
    padding: 0; }
    @media (min-width: 1200px) {
      .component.two-section-grid-anchorlinks > .component-content {
        position: relative;
        width: 90.25rem;
        max-width: 1140px; } }
    @media (min-width: 992px) {
      .component.two-section-grid-anchorlinks > .component-content {
        display: flex; } }
    .component.two-section-grid-anchorlinks > .component-content .section-one {
      flex: 1; }
      .component.two-section-grid-anchorlinks > .component-content .section-one .component {
        height: 100%; }
        .component.two-section-grid-anchorlinks > .component-content .section-one .component .component-content {
          padding: 2rem;
          width: auto;
          height: 100vh; }
          .component.two-section-grid-anchorlinks > .component-content .section-one .component .component-content ul {
            list-style-position: outside; }
            .component.two-section-grid-anchorlinks > .component-content .section-one .component .component-content ul li {
              text-indent: 0;
              padding-left: 0.25rem;
              line-height: 1.6; }
          .component.two-section-grid-anchorlinks > .component-content .section-one .component .component-content .backToTop-link {
            display: none;
            position: fixed;
            bottom: 1rem;
            left: 1rem;
            transition: background 0.93s ease; }
            @media (min-width: 992px) {
              .component.two-section-grid-anchorlinks > .component-content .section-one .component .component-content .backToTop-link {
                left: unset; } }
            .component.two-section-grid-anchorlinks > .component-content .section-one .component .component-content .backToTop-link .icon-interface-top {
              display: flex;
              align-items: center; }
              .component.two-section-grid-anchorlinks > .component-content .section-one .component .component-content .backToTop-link .icon-interface-top:before {
                content: "\eb24";
                background-color: #1890ba;
                border-radius: 100%;
                color: #ffffff;
                font-weight: bold;
                padding: 0.5rem 0.5rem 0.5rem 0.7rem;
                font-size: 1.5rem;
                margin-right: 1.5rem; }
                @media (min-width: 992px) {
                  .component.two-section-grid-anchorlinks > .component-content .section-one .component .component-content .backToTop-link .icon-interface-top:before {
                    margin-right: 0.5rem; } }
            .component.two-section-grid-anchorlinks > .component-content .section-one .component .component-content .backToTop-link:hover .icon-interface-top:before {
              background-color: #808285; }
    .component.two-section-grid-anchorlinks > .component-content .section-two {
      flex: 2; }
      .component.two-section-grid-anchorlinks > .component-content .section-two .component-content table {
        width: 100% !important; }
    .component.two-section-grid-anchorlinks > .component-content .link-spacer {
      height: 0;
      padding: 0; }

.footer-two-section-grid > .component-content {
  padding: 2rem;
  width: 100%;
  margin: 0 auto; }
  @media (min-width: 1200px) {
    .footer-two-section-grid > .component-content {
      position: relative;
      width: 90.25rem;
      max-width: 1140px; } }
  @media (min-width: 768px) {
    .footer-two-section-grid > .component-content {
      display: flex;
      justify-content: space-between;
      align-items: center; } }
  .footer-two-section-grid > .component-content .section-one .component-content,
  .footer-two-section-grid > .component-content .section-two .component-content {
    width: auto;
    padding: 0; }
  .footer-two-section-grid > .component-content .footer-navigation .component-content ul {
    text-align: center;
    width: 100%;
    list-style: none; }
    @media (min-width: 768px) {
      .footer-two-section-grid > .component-content .footer-navigation .component-content ul {
        display: flex;
        align-items: center;
        justify-content: center; } }
    .footer-two-section-grid > .component-content .footer-navigation .component-content ul li {
      border-bottom: 1px solid #999999;
      padding-bottom: 1rem;
      padding-top: 1rem;
      margin: 0; }
      .footer-two-section-grid > .component-content .footer-navigation .component-content ul li:last-child {
        border-bottom: 0; }
      @media (min-width: 768px) {
        .footer-two-section-grid > .component-content .footer-navigation .component-content ul li {
          border-bottom: 0;
          text-indent: 0;
          margin: 0;
          border-right: 1px solid #999999;
          padding: 0.1em 1em; } }
      .footer-two-section-grid > .component-content .footer-navigation .component-content ul li:first-child {
        padding-left: 0;
        margin-left: 0; }
      .footer-two-section-grid > .component-content .footer-navigation .component-content ul li:last-child {
        border-right: 0; }
      .footer-two-section-grid > .component-content .footer-navigation .component-content ul li a {
        font-weight: 400; }
  .footer-two-section-grid > .component-content .footer-copyright {
    text-align: center;
    color: #999999; }
    @media (min-width: 992px) {
      .footer-two-section-grid > .component-content .footer-copyright {
        text-align: right; } }

@media only screen and (max-width: 767px) {
  html,
  body {
    font-size: 14px !important; } }

@media only screen and (min-width: 768px) {
  html,
  body {
    font-size: 15px !important; } }

@media only screen and (min-width: 1200px) {
  html,
  body {
    font-size: 16px !important; } }

/*
 THEMING
*/
@media only screen and (max-width: 767px) {
  html,
  body {
    font-size: 14px !important; } }

@media only screen and (min-width: 768px) {
  html,
  body {
    font-size: 15px !important; } }

@media only screen and (min-width: 1200px) {
  html,
  body {
    font-size: 16px !important; } }

/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent; }

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0; }
  .slick-list:focus {
    outline: none; }
  .slick-list.dragging {
    cursor: pointer;
    cursor: hand; }

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto; }
  .slick-track:before, .slick-track:after {
    content: "";
    display: table; }
  .slick-track:after {
    clear: both; }
  .slick-loading .slick-track {
    visibility: hidden; }

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none; }
  [dir="rtl"] .slick-slide {
    float: right; }
  .slick-slide img {
    display: block; }
  .slick-slide.slick-loading img {
    display: none; }
  .slick-slide.dragging img {
    pointer-events: none; }
  .slick-initialized .slick-slide {
    display: block; }
  .slick-loading .slick-slide {
    visibility: hidden; }
  .slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent; }

.slick-arrow.slick-hidden {
  display: none; }

.component.text-image-spot .text-image-spot-image-container {
  background-size: 0px; }

.component.text-image-spot .text-image-spot-image img {
  max-width: 100%;
  height: auto; }

.component.text-image-spot .text-image-spot-text-container {
  padding: 2rem;
  width: 100%;
  margin: 0 auto; }
  @media (min-width: 1200px) {
    .component.text-image-spot .text-image-spot-text-container {
      position: relative;
      width: 90.25rem;
      max-width: 1140px; } }

.component.text-image-spot.inoborder {
  padding-top: 0;
  padding-bottom: 0; }
  .component.text-image-spot.inoborder .text-image-spot-text-container {
    padding-top: 1.25rem;
    padding-bottom: 1.25rem; }
    @media only screen and (max-width: 767px) {
      .component.text-image-spot.inoborder .text-image-spot-text-container {
        padding-top: 0.625rem; } }
    @media only screen and (max-width: 767px) {
      .component.text-image-spot.inoborder .text-image-spot-text-container {
        padding-bottom: 0.625rem; } }
    .component.text-image-spot.inoborder .text-image-spot-text-container.repeating-background {
      padding-top: 0; }

@media only screen and (max-width: 767px) {
  .component.text-image-spot.ialigncenter .text-image-spot-image-container {
    text-align: center; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .component.text-image-spot.ialigncenter .text-image-spot-image-container {
    text-align: center; } }

@media only screen and (max-width: 767px) {
  .component.text-image-spot.taligncenter .text-image-spot-text-container {
    text-align: center; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .component.text-image-spot.taligncenter .text-image-spot-text-container {
    text-align: center; } }

.component.text-image-spot.multiple-images {
  margin-top: 2rem;
  position: relative; }
  .component.text-image-spot.multiple-images.iright .text-image-spot-image-elements {
    margin-top: 1rem; }
  .component.text-image-spot.multiple-images .text-image-spot-image-container:after {
    content: "";
    display: table;
    clear: both; }

.component.text-image-spot .text-image-spot-image-elements {
  max-width: 80%;
  margin: 0 auto; }
  .component.text-image-spot .text-image-spot-image-elements:after {
    content: "";
    display: table;
    clear: both; }
  .component.text-image-spot .text-image-spot-image-elements.slick-slider .text-image-spot-element {
    max-width: none; }

.component.text-image-spot .text-image-spot-element {
  display: block;
  float: right;
  padding: 0;
  max-width: 25%; }
  .component.text-image-spot .text-image-spot-element:before {
    display: none; }

.component.text-image-spot .text-image-spot-image-element {
  padding-left: 1rem; }
  .component.text-image-spot .text-image-spot-image-element img {
    max-width: 100%;
    height: auto;
    border-bottom: 2px solid transparent; }
  .component.text-image-spot .text-image-spot-image-element img:hover, .component.text-image-spot .text-image-spot-image-element img.selected {
    border-bottom: 2px solid grey; }

.component.text-image-spot .slick-arrow {
  font-size: 0;
  line-height: 0;
  position: absolute;
  top: 50%;
  display: block;
  width: 30px;
  height: 30px;
  margin-top: -15px;
  padding: 0;
  cursor: pointer;
  color: transparent;
  outline: none;
  background: transparent;
  border: solid 2px grey;
  border-radius: 15px; }
  .component.text-image-spot .slick-arrow:before {
    position: relative;
    top: 1px;
    font-family: "";
    font-size: 16px;
    line-height: 1;
    color: grey; }
  .component.text-image-spot .slick-arrow.slick-next {
    right: -10%;
    z-index: 100; }
    .component.text-image-spot .slick-arrow.slick-next:before {
      content: "";
      left: 1px; }
  .component.text-image-spot .slick-arrow.slick-prev {
    left: -10%;
    z-index: 100; }
    .component.text-image-spot .slick-arrow.slick-prev:before {
      content: "";
      right: 1px; }
  .component.text-image-spot .slick-arrow.slick-disabled {
    cursor: default;
    opacity: 0.5; }

@media only screen and (min-width: 992px) {
  .component.text-image-spot {
    display: table;
    width: 100%;
    table-layout: fixed; }
    .component.text-image-spot .component-content {
      display: table-row;
      width: 100%; }
    .component.text-image-spot .text-image-spot-image-container,
    .component.text-image-spot .text-image-spot-text-container {
      display: table-cell;
      width: 50%; }
    .component.text-image-spot .text-image-spot-text {
      position: relative; }
    .component.text-image-spot:not(.iright) .text-image-spot-text {
      float: left;
      padding-left: 1.25rem; } }
    @media only screen and (min-width: 992px) and (max-width: 767px) {
      .component.text-image-spot:not(.iright) .text-image-spot-text {
        padding-left: 0.625rem; } }

@media only screen and (min-width: 992px) {
    .component.text-image-spot.iright {
      direction: rtl; }
      .component.text-image-spot.iright .text-image-spot-image-container {
        direction: ltr; }
      .component.text-image-spot.iright .text-image-spot-text-container {
        direction: ltr; }
      .component.text-image-spot.iright .text-image-spot-text {
        float: right;
        padding-right: 1.25rem; } }
      @media only screen and (min-width: 992px) and (max-width: 767px) {
        .component.text-image-spot.iright .text-image-spot-text {
          padding-right: 0.625rem; } }

@media only screen and (min-width: 992px) {
    .component.text-image-spot .text-image-spot-text {
      box-sizing: border-box;
      width: 45.125rem;
      max-width: 1140px; }
    .component.text-image-spot.tslim .text-image-spot-text {
      width: 38.5rem; }
    .component.text-image-spot.tw40 .text-image-spot-text {
      position: relative;
      width: 36.1rem;
      max-width: 912px;
      left: 9.025rem; }
    .component.text-image-spot.tw40.iright .text-image-spot-text {
      left: -9.025rem; }
    .component.text-image-spot.tw40.tslim .text-image-spot-text {
      width: 30.8rem;
      left: 7.7rem; }
    .component.text-image-spot.tw60 .text-image-spot-text {
      position: relative;
      width: 54.15rem;
      max-width: 1368px;
      left: -9.025rem; }
    .component.text-image-spot.tw60.tslim .text-image-spot-text {
      width: 46.2rem;
      left: -7.7rem; }
    .component.text-image-spot .text-image-spot-text-container {
      vertical-align: top; }
    .component.text-image-spot.tposmiddle .text-image-spot-text-container {
      vertical-align: middle; }
    .component.text-image-spot.tposbottom .text-image-spot-text-container {
      vertical-align: bottom; }
    .component.text-image-spot .text-image-spot-image {
      box-sizing: border-box;
      width: 100%;
      float: left; }
      .component.text-image-spot .text-image-spot-image img {
        float: right;
        max-width: 100%;
        height: auto; }
    .component.text-image-spot.inormal .text-image-spot-image img {
      width: 45.125rem;
      max-width: 1140px; }
    .component.text-image-spot.islim .text-image-spot-image img {
      width: 38.5rem;
      max-width: 1140px; }
    .component.text-image-spot.iright .text-image-spot-image img {
      float: left; }
    .component.text-image-spot.ialignborder:not(.iright) .text-image-spot-image img {
      float: right; }
    .component.text-image-spot.iw40 .text-image-spot-image {
      width: 80%; }
    .component.text-image-spot.iw40.iright .text-image-spot-image {
      margin-left: 20%; }
    .component.text-image-spot.iw40.inormal .text-image-spot-image {
      width: 36.1rem; }
    .component.text-image-spot.iw40.inormal.iright .text-image-spot-image {
      margin-left: 9.025rem; }
    .component.text-image-spot.iw40.islim .text-image-spot-image {
      width: 30.8rem; }
    .component.text-image-spot.iw40.islim.iright .text-image-spot-image {
      margin-left: 7.7rem; }
    .component.text-image-spot.iw60 .text-image-spot-image {
      width: 120%; }
    .component.text-image-spot.iw60.iright .text-image-spot-image {
      margin-left: -20%; }
    .component.text-image-spot.iw60.inormal .text-image-spot-image {
      width: 54.15rem; }
    .component.text-image-spot.iw60.inormal.iright .text-image-spot-image {
      margin-left: -9.025rem; }
    .component.text-image-spot.iw60.islim .text-image-spot-image {
      width: 46.2rem; }
    .component.text-image-spot.iw60.islim.iright .text-image-spot-image {
      margin-left: -7.7rem; }
    .component.text-image-spot .text-image-spot-image-container {
      vertical-align: top; }
    .component.text-image-spot.iposmiddle .text-image-spot-image-container {
      vertical-align: middle; }
    .component.text-image-spot.iposbottom .text-image-spot-image-container {
      vertical-align: bottom; }
    .component.text-image-spot.tforceheight .text-image-spot-image-container {
      background-size: cover;
      background-position: center; }
    .component.text-image-spot.tforceheight .text-image-spot-image img {
      display: none; }
    .component.text-image-spot.tforceheight:not(.iright).inormal .text-image-spot-image {
      left: auto;
      right: 0; }
    .component.text-image-spot.tforceheight:not(.iright).inormal.iw60 .text-image-spot-image {
      right: -9.025rem; }
    .component.text-image-spot.tforceheight:not(.iright).inormal.iw40 .text-image-spot-image {
      right: 9.025rem; }
    .component.text-image-spot.tforceheight:not(.iright).islim .text-image-spot-image {
      left: auto;
      right: 0; }
    .component.text-image-spot.tforceheight:not(.iright).islim.iw60 .text-image-spot-image {
      right: -7.7rem; }
    .component.text-image-spot.tforceheight:not(.iright).islim.iw40 .text-image-spot-image {
      right: 7.7rem; }
  .multiple-images.iright .text-image-spot-image-elements {
    float: left; }
  .multiple-images .text-image-spot-image-elements {
    max-width: 90%;
    float: right; }
  .multiple-images .slick-arrow.slick-next {
    right: -40px; }
  .multiple-images .slick-arrow.slick-prev {
    left: -40px; } }

.component.text-image-spot {
  display: block; }
  .component.text-image-spot .component-content {
    display: flex;
    justify-content: space-between; }
    .component.text-image-spot .component-content .text-image-spot-image-container {
      flex: 2;
      display: flex;
      align-items: center; }
      .component.text-image-spot .component-content .text-image-spot-image-container .text-image-spot-image {
        float: none; }
        .component.text-image-spot .component-content .text-image-spot-image-container .text-image-spot-image img {
          float: none; }
    .component.text-image-spot .component-content .text-image-spot-text-container {
      flex: 3; }
      .component.text-image-spot .component-content .text-image-spot-text-container .text-image-spot-text {
        float: none;
        width: auto; }

/*
 THEMING
*/
@media only screen and (max-width: 767px) {
  html,
  body {
    font-size: 14px !important; } }

@media only screen and (min-width: 768px) {
  html,
  body {
    font-size: 15px !important; } }

@media only screen and (min-width: 1200px) {
  html,
  body {
    font-size: 16px !important; } }

.component.hearing-test-spot:not(.quiz) {
  padding: 0; }

.component.hearing-test-spot:not(.quiz) .step-manager {
  position: relative;
  width: 100%;
  overflow: hidden; }

.component.hearing-test-spot:not(.quiz) .progress-bar {
  width: 100%;
  background: #4d4d4d;
  padding: 10px 0 5px;
  position: relative; }
  @media only screen and (min-width: 768px) {
    .component.hearing-test-spot:not(.quiz) .progress-bar {
      position: absolute;
      z-index: 997;
      top: 99px;
      left: 0;
      padding: 0; } }
  .component.hearing-test-spot:not(.quiz) .progress-bar__blocks {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background-color: #ffffff;
    border-top: 1px solid #ffffff;
    border-bottom: 1px solid #ffffff; }
    @media only screen and (min-width: 768px) {
      .component.hearing-test-spot:not(.quiz) .progress-bar__blocks {
        border: 0; } }
  .component.hearing-test-spot:not(.quiz) .progress-bar__block {
    height: 6px;
    background-color: #4d4d4d; }
    .component.hearing-test-spot:not(.quiz) .progress-bar__block--active {
      background-color: #f7bd00; }
    .component.hearing-test-spot:not(.quiz) .progress-bar__block--current {
      background-color: #f7941d; }
  .component.hearing-test-spot:not(.quiz) .progress-bar__balloon {
    position: absolute;
    top: calc(100% + 7px);
    padding: 0.4rem 0.8rem;
    background-color: #f7941d;
    border-radius: 4px;
    transform: translateX(-50%);
    transition: left 0.3s ease-in-out;
    z-index: 1; }
    @media only screen and (min-width: 768px) {
      .component.hearing-test-spot:not(.quiz) .progress-bar__balloon {
        display: block;
        padding: 10px; } }
    .component.hearing-test-spot:not(.quiz) .progress-bar__balloon:before {
      content: '';
      position: absolute;
      width: 0;
      height: 0;
      top: -5px;
      left: 50%;
      transform: translateX(-50%);
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      border-bottom: 5px solid #f7941d; }
  .component.hearing-test-spot:not(.quiz) .progress-bar__text {
    color: #ffffff;
    white-space: nowrap;
    font-size: 1.1rem; }

.component.hearing-test-spot:not(.quiz) .step-header {
  display: flex;
  flex-direction: column;
  max-width: 400px;
  margin: 0 0 1rem 0; }
  .component.hearing-test-spot:not(.quiz) .step-header__icon-wrapper div div {
    width: 50px;
    height: 50px;
    margin-bottom: 1rem; }
  .component.hearing-test-spot:not(.quiz) .step-header__icon {
    width: 100%;
    max-height: 50px;
    fill: #ffffff; }
  .component.hearing-test-spot:not(.quiz) .step-header__title {
    font-size: 16px;
    color: #ffffff;
    margin: 0 0 2rem; }

.component.hearing-test-spot:not(.quiz) .step--button .step-header {
  margin: 0 0 3rem 0; }
  @media only screen and (min-width: 768px) {
    .component.hearing-test-spot:not(.quiz) .step--button .step-header {
      margin: 0 0 1rem 0; } }
  .component.hearing-test-spot:not(.quiz) .step--button .step-header .step-header__title {
    margin: 0; }

.component.hearing-test-spot:not(.quiz) .step {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  width: 100%;
  min-height: calc(100vh - 71px);
  padding: 2rem 0 0 0;
  color: #ffffff; }
  @media only screen and (max-width: 350px) {
    .component.hearing-test-spot:not(.quiz) .step {
      padding: 3.5rem 0 0 0; } }
  @media all and (-ms-high-contrast: none) {
    .component.hearing-test-spot:not(.quiz) .step {
      min-height: auto; } }
  @media only screen and (min-width: 768px) {
    .component.hearing-test-spot:not(.quiz) .step {
      padding: 6rem 0 6rem;
      min-height: 90vh;
      background-position: center top;
      background-size: cover;
      background-repeat: no-repeat; } }
  @media all and (-ms-high-contrast: none) and (min-width: 768px) {
    .component.hearing-test-spot:not(.quiz) .step {
      min-height: auto; } }
  .component.hearing-test-spot:not(.quiz) .step__wrapper {
    padding: 2rem;
    width: 100%;
    margin: 0 auto; }
    @media (min-width: 1200px) {
      .component.hearing-test-spot:not(.quiz) .step__wrapper {
        position: relative;
        width: 90.25rem;
        max-width: 1140px; } }
  .component.hearing-test-spot:not(.quiz) .step__content {
    display: block;
    width: 100%;
    max-width: 800px; }
    @media only screen and (min-width: 768px) {
      .component.hearing-test-spot:not(.quiz) .step__content {
        width: 50%; } }
  .component.hearing-test-spot:not(.quiz) .step__text {
    background-color: rgba(77, 77, 77, 0.9);
    padding: 2rem;
    margin-bottom: 3px; }
    @media only screen and (max-width: 350px) {
      .component.hearing-test-spot:not(.quiz) .step__text {
        padding: 1rem; } }
  .component.hearing-test-spot:not(.quiz) .step__buttons {
    background-color: rgba(77, 77, 77, 0.9);
    padding: 1rem 2rem;
    display: flex;
    justify-content: center; }
  .component.hearing-test-spot:not(.quiz) .step__title {
    font-size: 1.8rem;
    margin-bottom: 1rem;
    color: #ffffff; }
  .component.hearing-test-spot:not(.quiz) .step__body {
    font-size: 16px;
    color: #ffffff;
    margin: 2rem 0 4rem 0; }
  .component.hearing-test-spot:not(.quiz) .step-enter-active {
    position: relative; }
  .component.hearing-test-spot:not(.quiz) .step-exit {
    position: absolute; }
  .component.hearing-test-spot:not(.quiz) .step--button .step__title, .component.hearing-test-spot:not(.quiz) .step--slider .step__title {
    margin-bottom: 0; }

.component.hearing-test-spot:not(.quiz) .step--button .step__text {
  background-color: rgba(255, 255, 255, 0.2); }
  @media only screen and (min-width: 768px) {
    .component.hearing-test-spot:not(.quiz) .step--button .step__text {
      background-color: rgba(77, 77, 77, 0.9);
      padding: 2rem 3rem 3rem 3rem; } }

.component.hearing-test-spot:not(.quiz) .step--button .step__title {
  font-size: 1.5rem; }
  @media only screen and (min-width: 768px) {
    .component.hearing-test-spot:not(.quiz) .step--button .step__title {
      font-size: 1.8rem;
      font-family: CentraleSansMedium, sans-serif; } }

.component.hearing-test-spot:not(.quiz) .step--button .step__buttons {
  background-color: rgba(255, 255, 255, 0.2); }
  @media only screen and (min-width: 768px) {
    .component.hearing-test-spot:not(.quiz) .step--button .step__buttons {
      background-color: rgba(77, 77, 77, 0.9);
      padding: 1rem 3rem; } }

.component.hearing-test-spot:not(.quiz) .step--tone-calibration-intro .step__content,
.component.hearing-test-spot:not(.quiz) .step--tone-calibration-play-test-sound .step__content,
.component.hearing-test-spot:not(.quiz) .step--tone-calibration-adjust-volume .step__content,
.component.hearing-test-spot:not(.quiz) .step--tone .step__content {
  width: 100%;
  max-width: 100%;
  position: relative; }

.component.hearing-test-spot:not(.quiz) .step--tone-calibration-intro .step__text,
.component.hearing-test-spot:not(.quiz) .step--tone-calibration-play-test-sound .step__text,
.component.hearing-test-spot:not(.quiz) .step--tone-calibration-adjust-volume .step__text,
.component.hearing-test-spot:not(.quiz) .step--tone .step__text {
  background-color: rgba(255, 255, 255, 0.2);
  padding: 2rem 2rem 4rem 2rem;
  margin-bottom: 6px; }
  .component.hearing-test-spot:not(.quiz) .step--tone-calibration-intro .step__text .step__title,
  .component.hearing-test-spot:not(.quiz) .step--tone-calibration-play-test-sound .step__text .step__title,
  .component.hearing-test-spot:not(.quiz) .step--tone-calibration-adjust-volume .step__text .step__title,
  .component.hearing-test-spot:not(.quiz) .step--tone .step__text .step__title {
    display: flex;
    align-items: center;
    max-width: 100%; }
    @media only screen and (min-width: 768px) {
      .component.hearing-test-spot:not(.quiz) .step--tone-calibration-intro .step__text .step__title,
      .component.hearing-test-spot:not(.quiz) .step--tone-calibration-play-test-sound .step__text .step__title,
      .component.hearing-test-spot:not(.quiz) .step--tone-calibration-adjust-volume .step__text .step__title,
      .component.hearing-test-spot:not(.quiz) .step--tone .step__text .step__title {
        max-width: 60%; } }
  .component.hearing-test-spot:not(.quiz) .step--tone-calibration-intro .step__text .step__body,
  .component.hearing-test-spot:not(.quiz) .step--tone-calibration-play-test-sound .step__text .step__body,
  .component.hearing-test-spot:not(.quiz) .step--tone-calibration-adjust-volume .step__text .step__body,
  .component.hearing-test-spot:not(.quiz) .step--tone .step__text .step__body {
    max-width: 100%; }
    @media only screen and (min-width: 768px) {
      .component.hearing-test-spot:not(.quiz) .step--tone-calibration-intro .step__text .step__body,
      .component.hearing-test-spot:not(.quiz) .step--tone-calibration-play-test-sound .step__text .step__body,
      .component.hearing-test-spot:not(.quiz) .step--tone-calibration-adjust-volume .step__text .step__body,
      .component.hearing-test-spot:not(.quiz) .step--tone .step__text .step__body {
        max-width: 60%; } }

.component.hearing-test-spot:not(.quiz) .step--tone-calibration-intro .step__buttons,
.component.hearing-test-spot:not(.quiz) .step--tone-calibration-play-test-sound .step__buttons,
.component.hearing-test-spot:not(.quiz) .step--tone-calibration-adjust-volume .step__buttons,
.component.hearing-test-spot:not(.quiz) .step--tone .step__buttons {
  background-color: rgba(255, 255, 255, 0.2);
  padding: 1rem 2rem;
  display: flex;
  justify-content: center; }
  .component.hearing-test-spot:not(.quiz) .step--tone-calibration-intro .step__buttons .step-button:first-of-type,
  .component.hearing-test-spot:not(.quiz) .step--tone-calibration-play-test-sound .step__buttons .step-button:first-of-type,
  .component.hearing-test-spot:not(.quiz) .step--tone-calibration-adjust-volume .step__buttons .step-button:first-of-type,
  .component.hearing-test-spot:not(.quiz) .step--tone .step__buttons .step-button:first-of-type {
    margin-right: 0; }

.component.hearing-test-spot:not(.quiz) .step--tone-calibration-adjust-volume .step__text {
  padding: 2rem; }
  @media only screen and (max-width: 350px) {
    .component.hearing-test-spot:not(.quiz) .step--tone-calibration-adjust-volume .step__text {
      padding: 1rem; } }
  @media only screen and (min-width: 768px) {
    .component.hearing-test-spot:not(.quiz) .step--tone-calibration-adjust-volume .step__text {
      padding-bottom: 4rem; } }

.component.hearing-test-spot:not(.quiz) .step--tone-calibration-adjust-volume .step__title {
  font-size: 1.5rem; }
  @media only screen and (min-width: 768px) {
    .component.hearing-test-spot:not(.quiz) .step--tone-calibration-adjust-volume .step__title {
      font-size: 1.8rem; } }

.component.hearing-test-spot:not(.quiz) .step--tone .step__text {
  padding: 2rem; }
  @media only screen and (max-width: 350px) {
    .component.hearing-test-spot:not(.quiz) .step--tone .step__text {
      padding: 1rem; } }
  @media only screen and (min-width: 768px) {
    .component.hearing-test-spot:not(.quiz) .step--tone .step__text {
      padding-bottom: 4rem; } }
  .component.hearing-test-spot:not(.quiz) .step--tone .step__text .step__body {
    margin: 1rem 0;
    font-size: 1.5rem; }
    @media only screen and (min-width: 768px) {
      .component.hearing-test-spot:not(.quiz) .step--tone .step__text .step__body {
        font-size: 1.8rem; } }
  .component.hearing-test-spot:not(.quiz) .step--tone .step__text .step__title {
    margin-bottom: 1rem;
    font-size: 1.5rem; }
    @media only screen and (min-width: 768px) {
      .component.hearing-test-spot:not(.quiz) .step--tone .step__text .step__title {
        font-size: 1.8rem; } }

.component.hearing-test-spot:not(.quiz) .step--tone .step__buttons .step-button:first-of-type {
  margin-right: 3rem; }

.component.hearing-test-spot:not(.quiz) .step--tone-calibration-intro .step-circle,
.component.hearing-test-spot:not(.quiz) .step--tone-calibration-play-test-sound .step-circle {
  border-radius: 50%;
  margin: 0 1rem 0 0; }
  @media only screen and (min-width: 768px) {
    .component.hearing-test-spot:not(.quiz) .step--tone-calibration-intro .step-circle,
    .component.hearing-test-spot:not(.quiz) .step--tone-calibration-play-test-sound .step-circle {
      margin: 0 2rem 0 0; } }

.component.hearing-test-spot:not(.quiz) .step--tone-calibration-intro .step__text,
.component.hearing-test-spot:not(.quiz) .step--tone-calibration-play-test-sound .step__text {
  padding: 2rem; }
  @media only screen and (max-width: 350px) {
    .component.hearing-test-spot:not(.quiz) .step--tone-calibration-intro .step__text,
    .component.hearing-test-spot:not(.quiz) .step--tone-calibration-play-test-sound .step__text {
      padding: 1rem; } }
  @media only screen and (min-width: 768px) {
    .component.hearing-test-spot:not(.quiz) .step--tone-calibration-intro .step__text,
    .component.hearing-test-spot:not(.quiz) .step--tone-calibration-play-test-sound .step__text {
      padding-bottom: 4rem; } }
  .component.hearing-test-spot:not(.quiz) .step--tone-calibration-intro .step__text .step__title,
  .component.hearing-test-spot:not(.quiz) .step--tone-calibration-play-test-sound .step__text .step__title {
    font-size: 1.5rem; }
    @media only screen and (min-width: 768px) {
      .component.hearing-test-spot:not(.quiz) .step--tone-calibration-intro .step__text .step__title,
      .component.hearing-test-spot:not(.quiz) .step--tone-calibration-play-test-sound .step__text .step__title {
        font-size: 1.8rem; } }
  .component.hearing-test-spot:not(.quiz) .step--tone-calibration-intro .step__text .step__body,
  .component.hearing-test-spot:not(.quiz) .step--tone-calibration-play-test-sound .step__text .step__body {
    margin: 1rem 0;
    font-size: 1rem; }
    @media only screen and (min-width: 768px) {
      .component.hearing-test-spot:not(.quiz) .step--tone-calibration-intro .step__text .step__body,
      .component.hearing-test-spot:not(.quiz) .step--tone-calibration-play-test-sound .step__text .step__body {
        margin: 2rem 0 4rem 0;
        font-size: 16px; } }

.component.hearing-test-spot:not(.quiz) .step-button {
  font-size: 1.15rem;
  padding: 0.5rem 2rem; }
  .component.hearing-test-spot:not(.quiz) .step-button:first-of-type {
    margin-right: 3rem; }

.component.hearing-test-spot:not(.quiz) .step-circle {
  border: 2px solid #ffffff;
  width: 2.35rem;
  height: 2.35rem;
  font-size: 1.3rem;
  line-height: 1.9rem;
  border-radius: 2px;
  overflow: hidden;
  text-align: center;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center; }

.rc-slider {
  position: relative;
  height: 14px;
  padding: 5px 0;
  width: 100%;
  border-radius: 6px;
  -ms-touch-action: none;
  touch-action: none;
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

.rc-slider * {
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

.rc-slider-rail {
  position: absolute;
  width: 100%;
  background-color: #e9e9e9;
  height: 4px;
  border-radius: 6px; }

.rc-slider-track {
  position: absolute;
  left: 0;
  height: 4px;
  border-radius: 6px;
  background-color: #abe2fb; }

.rc-slider-handle {
  position: absolute;
  margin-left: -7px;
  margin-top: -5px;
  width: 14px;
  height: 14px;
  cursor: pointer;
  cursor: -webkit-grab;
  cursor: grab;
  border-radius: 50%;
  border: solid 2px #96dbfa;
  background-color: #fff;
  -ms-touch-action: pan-x;
  touch-action: pan-x; }

.rc-slider-handle:focus {
  border-color: #57c5f7;
  box-shadow: 0 0 0 5px #96dbfa;
  outline: none; }

.rc-slider-handle-click-focused:focus {
  border-color: #96dbfa;
  box-shadow: unset; }

.rc-slider-handle:hover {
  border-color: #57c5f7; }

.rc-slider-handle:active, .rc-slider-handle.nice-select.open {
  border-color: #57c5f7;
  box-shadow: 0 0 5px #57c5f7;
  cursor: -webkit-grabbing;
  cursor: grabbing; }

.rc-slider-mark {
  position: absolute;
  top: 18px;
  left: 0;
  width: 100%;
  font-size: 12px; }

.rc-slider-mark-text {
  position: absolute;
  display: inline-block;
  vertical-align: middle;
  text-align: center;
  cursor: pointer;
  color: #999; }

.rc-slider-mark-text-active {
  color: #666; }

.rc-slider-step {
  position: absolute;
  width: 100%;
  height: 4px;
  background: transparent; }

.rc-slider-dot {
  position: absolute;
  bottom: -2px;
  margin-left: -4px;
  width: 8px;
  height: 8px;
  border: 2px solid #e9e9e9;
  background-color: #fff;
  cursor: pointer;
  border-radius: 50%;
  vertical-align: middle; }

.rc-slider-dot-active {
  border-color: #96dbfa; }

.rc-slider-disabled {
  background-color: #e9e9e9; }

.rc-slider-disabled .rc-slider-track {
  background-color: #ccc; }

.rc-slider-disabled .rc-slider-handle,
.rc-slider-disabled .rc-slider-dot {
  border-color: #ccc;
  box-shadow: none;
  background-color: #fff;
  cursor: not-allowed; }

.rc-slider-disabled .rc-slider-mark-text,
.rc-slider-disabled .rc-slider-dot {
  cursor: not-allowed !important; }

.rc-slider-vertical {
  width: 14px;
  height: 100%;
  padding: 0 5px; }

.rc-slider-vertical .rc-slider-rail {
  height: 100%;
  width: 4px; }

.rc-slider-vertical .rc-slider-track {
  left: 5px;
  bottom: 0;
  width: 4px; }

.rc-slider-vertical .rc-slider-handle {
  margin-left: -5px;
  margin-bottom: -7px;
  -ms-touch-action: pan-y;
  touch-action: pan-y; }

.rc-slider-vertical .rc-slider-mark {
  top: 0;
  left: 18px;
  height: 100%; }

.rc-slider-vertical .rc-slider-step {
  height: 100%;
  width: 4px; }

.rc-slider-vertical .rc-slider-dot {
  left: 2px;
  margin-bottom: -4px; }

.rc-slider-vertical .rc-slider-dot:first-child {
  margin-bottom: -4px; }

.rc-slider-vertical .rc-slider-dot:last-child {
  margin-bottom: -4px; }

.rc-slider-tooltip-zoom-down-enter,
.rc-slider-tooltip-zoom-down-appear {
  animation-duration: 0.3s;
  animation-fill-mode: both;
  display: block !important;
  animation-play-state: paused; }

.rc-slider-tooltip-zoom-down-leave {
  animation-duration: 0.3s;
  animation-fill-mode: both;
  display: block !important;
  animation-play-state: paused; }

.rc-slider-tooltip-zoom-down-enter.rc-slider-tooltip-zoom-down-enter-active,
.rc-slider-tooltip-zoom-down-appear.rc-slider-tooltip-zoom-down-appear-active {
  animation-name: rcSliderTooltipZoomDownIn;
  animation-play-state: running; }

.rc-slider-tooltip-zoom-down-leave.rc-slider-tooltip-zoom-down-leave-active {
  animation-name: rcSliderTooltipZoomDownOut;
  animation-play-state: running; }

.rc-slider-tooltip-zoom-down-enter,
.rc-slider-tooltip-zoom-down-appear {
  transform: scale(0, 0);
  animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1); }

.rc-slider-tooltip-zoom-down-leave {
  animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06); }

@keyframes rcSliderTooltipZoomDownIn {
  0% {
    opacity: 0;
    transform-origin: 50% 100%;
    transform: scale(0, 0); }
  100% {
    transform-origin: 50% 100%;
    transform: scale(1, 1); } }

@keyframes rcSliderTooltipZoomDownOut {
  0% {
    transform-origin: 50% 100%;
    transform: scale(1, 1); }
  100% {
    opacity: 0;
    transform-origin: 50% 100%;
    transform: scale(0, 0); } }

.rc-slider-tooltip {
  position: absolute;
  left: -9999px;
  top: -9999px;
  visibility: visible;
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

.rc-slider-tooltip * {
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

.rc-slider-tooltip-hidden {
  display: none; }

.rc-slider-tooltip-placement-top {
  padding: 4px 0 8px 0; }

.rc-slider-tooltip-inner {
  padding: 6px 2px;
  min-width: 24px;
  height: 24px;
  font-size: 12px;
  line-height: 1;
  color: #fff;
  text-align: center;
  text-decoration: none;
  background-color: #6c6c6c;
  border-radius: 6px;
  box-shadow: 0 0 4px #d9d9d9; }

.rc-slider-tooltip-arrow {
  position: absolute;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid; }

.rc-slider-tooltip-placement-top .rc-slider-tooltip-arrow {
  bottom: 4px;
  left: 50%;
  margin-left: -4px;
  border-width: 4px 4px 0;
  border-top-color: #6c6c6c; }

.component.hearing-test-spot:not(.quiz) .step--slider .slider__wrapper {
  width: 100%;
  max-width: 400px;
  padding: 0;
  margin: 0 auto 3rem;
  position: relative; }

.component.hearing-test-spot:not(.quiz) .step--slider .slider__container {
  display: inline-block;
  width: 65%;
  margin: 0 1.5rem; }

.component.hearing-test-spot:not(.quiz) .step--slider .step-circle {
  cursor: pointer;
  background-color: transparent;
  line-height: 1;
  font-size: 1rem;
  overflow: hidden;
  padding: 0;
  user-select: none;
  color: #ffffff;
  font-family: "philips-global-icon-font"; }

.component.hearing-test-spot:not(.quiz) .step--slider .rc-slider {
  display: block; }
  .component.hearing-test-spot:not(.quiz) .step--slider .rc-slider-dot {
    margin: 0;
    border-color: #4d4d4d; }
  .component.hearing-test-spot:not(.quiz) .step--slider .rc-slider-handle {
    border-color: #4d4d4d; }
    .component.hearing-test-spot:not(.quiz) .step--slider .rc-slider-handle:focus, .component.hearing-test-spot:not(.quiz) .step--slider .rc-slider-handle:active, .component.hearing-test-spot:not(.quiz) .step--slider .rc-slider-handle.nice-select.open {
      box-shadow: 0 0 5px #4d4d4d; }
  .component.hearing-test-spot:not(.quiz) .step--slider .rc-slider-rail {
    background-color: #f7941d; }
  .component.hearing-test-spot:not(.quiz) .step--slider .rc-slider-track {
    background-color: #4d4d4d; }
  .component.hearing-test-spot:not(.quiz) .step--slider .rc-slider-mark-text {
    color: #ffffff;
    white-space: nowrap; }

.component.hearing-test-spot:not(.quiz) .volume-controls {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 30rem;
  margin: 0 auto; }
  .component.hearing-test-spot:not(.quiz) .volume-controls .step-circle-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center; }
  .component.hearing-test-spot:not(.quiz) .volume-controls .step-circle {
    cursor: pointer;
    background-color: transparent;
    line-height: 1;
    font-size: 1rem;
    overflow: hidden;
    padding: 0;
    user-select: none;
    color: #ffffff;
    font-family: "philips-global-icon-font";
    border: 1px solid #ffffff;
    margin-bottom: 5px; }
  .component.hearing-test-spot:not(.quiz) .volume-controls .step-circle-label {
    font-size: 0.8571rem;
    line-height: 1.2857rem; }

.component.hearing-test-spot:not(.quiz) .volume-bars {
  display: inline-block;
  width: auto;
  margin: 0 1rem 0; }
  @media only screen and (min-width: 768px) {
    .component.hearing-test-spot:not(.quiz) .volume-bars {
      margin: 0 2rem 0; } }

.component.hearing-test-spot:not(.quiz) .volume-bar {
  display: inline-block;
  width: 15px;
  margin: 0 1.5px;
  cursor: pointer;
  border: 1px solid #ffffff;
  padding: 0; }
  @media only screen and (min-width: 768px) {
    .component.hearing-test-spot:not(.quiz) .volume-bar {
      width: 20px; } }
  .component.hearing-test-spot:not(.quiz) .volume-bar.filled {
    background-color: #ffffff; }
  .component.hearing-test-spot:not(.quiz) .volume-bar.empty {
    background-color: transparent; }

.component.hearing-test-spot:not(.quiz) .volume-bar:nth-child(1) {
  height: 7px; }

.component.hearing-test-spot:not(.quiz) .volume-bar:nth-child(2) {
  height: 14px; }

.component.hearing-test-spot:not(.quiz) .volume-bar:nth-child(3) {
  height: 21px; }

.component.hearing-test-spot:not(.quiz) .volume-bar:nth-child(4) {
  height: 28px; }

.component.hearing-test-spot:not(.quiz) .volume-bar:nth-child(5) {
  height: 35px; }

.component.hearing-test-spot:not(.quiz) .volume-bar:nth-child(6) {
  height: 42px; }

.component.hearing-test-spot:not(.quiz) .volume-bar:nth-child(7) {
  height: 49px; }

.component.hearing-test-spot:not(.quiz) .volume-bar:nth-child(8) {
  height: 56px; }

.component.hearing-test-spot:not(.quiz) .circular-progress-bar__background, .component.hearing-test-spot:not(.quiz) .circular-progress-bar__progress {
  fill: none; }

.component.hearing-test-spot:not(.quiz) .circular-progress-bar__background {
  stroke: #ffffff; }

.component.hearing-test-spot:not(.quiz) .circular-progress-bar__progress {
  stroke: #f7941d;
  stroke-linecap: round;
  stroke-linejoin: round; }

.component.hearing-test-spot:not(.quiz) .circular-progress-bar__text {
  font-size: 1em;
  font-weight: bold;
  fill: #4d4d4d; }

.component.hearing-test-spot:not(.quiz) .tone-progress {
  position: absolute;
  right: 1em;
  top: 1em; }
  .component.hearing-test-spot:not(.quiz) .tone-progress .circular-progress-bar__progress {
    stroke-linecap: butt;
    stroke-linejoin: butt; }
  .component.hearing-test-spot:not(.quiz) .tone-progress .circular-progress-bar__text {
    fill: white; }
  .component.hearing-test-spot:not(.quiz) .tone-progress .circular-progress-bar__background {
    stroke: #05294a; }

.component.hearing-test-spot:not(.quiz) .tone-player .circular-progress-bar__wrapper {
  position: relative;
  margin-top: 3rem;
  margin-bottom: 2rem;
  display: flex;
  justify-content: center; }

.component.hearing-test-spot:not(.quiz) .tone-player__control {
  cursor: pointer; }
  .component.hearing-test-spot:not(.quiz) .tone-player__control:after {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); }
  .component.hearing-test-spot:not(.quiz) .tone-player__control--play:after {
    content: 'PLAY'; }
  .component.hearing-test-spot:not(.quiz) .tone-player__control--stop:after {
    content: 'STOP'; }

.component.hearing-test-spot:not(.quiz) .tone-player__buttons {
  display: flex; }

.component.hearing-test-spot .component-content .hearing-test-container {
  position: relative; }
  .component.hearing-test-spot .component-content .hearing-test-container .progress-bar {
    top: 42px; }

.component.hearing-test-spot .component-content .tone-player__control:after {
  font-size: 35px; }

.component.hearing-test-spot .component-content .tone-player__control--play:after {
  content: "";
  transform: translate(-44%, -58%);
  font-family: 'icomoon-yourhearing' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.component.hearing-test-spot .component-content .tone-player__control--stop:after {
  content: "";
  transform: translate(-53%, -58%);
  font-family: 'icomoon-yourhearing' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.component.hearing-test-spot .component-content .step--tone-calibration-intro .step__text .step__body,
.component.hearing-test-spot .component-content .component.hearing-test-spot:not(.quiz) .step--tone-calibration-play-test-sound .step__text .step__body {
  font-size: 20px; }

#wrapper #content .component.hearing-test-intro > .component-content,
#wrapper #content .component.hearing-test-result > .component-content {
  width: auto;
  background: rgba(255, 255, 255, 0.2);
  padding: 1.5rem; }
  @media only screen and (min-width: 992px) {
    #wrapper #content .component.hearing-test-intro > .component-content,
    #wrapper #content .component.hearing-test-result > .component-content {
      padding: 2.5rem; } }
  #wrapper #content .component.hearing-test-intro > .component-content > .component.rich-text,
  #wrapper #content .component.hearing-test-result > .component-content > .component.rich-text {
    padding: 0; }
    #wrapper #content .component.hearing-test-intro > .component-content > .component.rich-text .component-content,
    #wrapper #content .component.hearing-test-result > .component-content > .component.rich-text .component-content {
      width: 100%;
      max-width: 100%; }
      #wrapper #content .component.hearing-test-intro > .component-content > .component.rich-text .component-content h1,
      #wrapper #content .component.hearing-test-result > .component-content > .component.rich-text .component-content h1 {
        font-family: CentraleSansMedium, sans-serif; }

#wrapper #content .component.hearing-test-intro {
  padding: 0; }
  #wrapper #content .component.hearing-test-intro .component.image-spot {
    padding-top: 2rem;
    padding-bottom: 0; }
    @media only screen and (min-width: 992px) {
      #wrapper #content .component.hearing-test-intro .component.image-spot {
        padding-top: 3rem; } }
    #wrapper #content .component.hearing-test-intro .component.image-spot .component-content {
      width: 100%;
      max-width: 100%; }
      @media only screen and (min-width: 992px) {
        #wrapper #content .component.hearing-test-intro .component.image-spot .component-content .image-spot-elements > li:first-child:nth-last-child(2) .image-spot-element-image,
        #wrapper #content .component.hearing-test-intro .component.image-spot .component-content .image-spot-elements > li:first-child:nth-last-child(2) ~ li .image-spot-element-image {
          padding: 0 0; }
        #wrapper #content .component.hearing-test-intro .component.image-spot .component-content .image-spot-elements > li:first-child:nth-last-child(3) .image-spot-element-image,
        #wrapper #content .component.hearing-test-intro .component.image-spot .component-content .image-spot-elements > li:first-child:nth-last-child(3) ~ li .image-spot-element-image {
          padding: 0 0; }
        #wrapper #content .component.hearing-test-intro .component.image-spot .component-content .image-spot-elements > li:first-child:nth-last-child(4) .image-spot-element-image,
        #wrapper #content .component.hearing-test-intro .component.image-spot .component-content .image-spot-elements > li:first-child:nth-last-child(4) ~ li .image-spot-element-image {
          padding: 0 0; }
        #wrapper #content .component.hearing-test-intro .component.image-spot .component-content .image-spot-elements > li:first-child:nth-last-child(5) .image-spot-element-image,
        #wrapper #content .component.hearing-test-intro .component.image-spot .component-content .image-spot-elements > li:first-child:nth-last-child(5) ~ li .image-spot-element-image {
          padding: 0 0; } }
      #wrapper #content .component.hearing-test-intro .component.image-spot .component-content .image-spot-elements .image-spot-element {
        padding-right: 1rem;
        margin-bottom: 0; }
        @media only screen and (max-width: 991px) {
          #wrapper #content .component.hearing-test-intro .component.image-spot .component-content .image-spot-elements .image-spot-element {
            width: 100%;
            display: flex;
            margin-bottom: 1.5rem; }
            #wrapper #content .component.hearing-test-intro .component.image-spot .component-content .image-spot-elements .image-spot-element .image-spot-element-image {
              display: flex;
              align-items: center;
              margin-bottom: 0;
              padding: 0;
              text-align: left; }
            #wrapper #content .component.hearing-test-intro .component.image-spot .component-content .image-spot-elements .image-spot-element .image-spot-element-header {
              max-width: 100%;
              display: flex;
              align-items: center;
              margin: 0 0 0 1.5rem;
              text-align: left; }
            #wrapper #content .component.hearing-test-intro .component.image-spot .component-content .image-spot-elements .image-spot-element .image-spot-element-text {
              display: none; } }
      #wrapper #content .component.hearing-test-intro .component.image-spot .component-content .image-spot-elements img {
        max-width: 50px; }
        @media only screen and (min-width: 992px) {
          #wrapper #content .component.hearing-test-intro .component.image-spot .component-content .image-spot-elements img {
            max-width: 80px; } }
  #wrapper #content .component.hearing-test-intro .component.rich-text .component-content [style] {
    text-align: center !important; }
  #wrapper #content .component.hearing-test-intro .component.rich-text .component-content h3 {
    font-size: 2.5rem;
    font-weight: normal; }
    @media (min-width: 992px) {
      #wrapper #content .component.hearing-test-intro .component.rich-text .component-content h3 {
        width: 56%; } }
  #wrapper #content .component.hearing-test-intro .component.rich-text .component-content h5 {
    font-size: 1.5rem; }
    @media (min-width: 992px) {
      #wrapper #content .component.hearing-test-intro .component.rich-text .component-content h5 {
        width: 56%; } }
  @media (min-width: 992px) {
    #wrapper #content .component.hearing-test-intro .component.rich-text .component-content p {
      width: 56%; } }
  @media only screen and (max-width: 991px) {
    #wrapper #content .component.hearing-test-intro .component.rich-text:last-of-type .component-content h3 {
      display: none; }
    #wrapper #content .component.hearing-test-intro .component.rich-text:last-of-type .component-content h3 + p {
      margin-top: 0; } }
  #wrapper #content .component.hearing-test-intro .contains-btn {
    margin-top: 0; }

#wrapper #content .component.hearing-test-intro-button {
  margin-top: 6px; }
  #wrapper #content .component.hearing-test-intro-button > .component-content {
    padding: 1rem 1.5rem; }
    @media only screen and (min-width: 992px) {
      #wrapper #content .component.hearing-test-intro-button > .component-content {
        padding: 1rem 2rem; } }
    #wrapper #content .component.hearing-test-intro-button > .component-content a {
      padding: 0.5rem 2rem; }
  #wrapper #content .component.hearing-test-intro-button .component.rich-text .component-content > * {
    width: 100%;
    text-align: center; }

#wrapper #content .component.hearing-test-result {
  padding-bottom: 2rem; }
  #wrapper #content .component.hearing-test-result .component.rich-text .component-content h1 {
    font-size: 2.5rem;
    font-weight: normal;
    font-style: normal;
    line-height: normal; }
  #wrapper #content .component.hearing-test-result .component.rich-text .component-content h3 {
    font-size: 1.5rem;
    font-weight: 400;
    font-style: normal;
    line-height: normal; }
  #wrapper #content .component.hearing-test-result .component.rich-text .component-content p {
    font-size: 1.15rem;
    line-height: 1.6; }
  #wrapper #content .component.hearing-test-result .component.rich-text .component-content a {
    margin-top: 0.5rem;
    padding: 0.5rem 2rem;
    font-weight: normal; }
    #wrapper #content .component.hearing-test-result .component.rich-text .component-content a:first-of-type {
      margin-right: 1rem; }
  #wrapper #content .component.hearing-test-result .component.two-section-grid > .component-content {
    width: 100%;
    padding: 2rem 0 0; }
    #wrapper #content .component.hearing-test-result .component.two-section-grid > .component-content .section-one {
      padding: 0 2rem 2rem; }
    #wrapper #content .component.hearing-test-result .component.two-section-grid > .component-content .component.form.hearing-test-result-form .component-content {
      padding: 0 !important; }
      #wrapper #content .component.hearing-test-result .component.two-section-grid > .component-content .component.form.hearing-test-result-form .component-content .scfForm {
        background-color: transparent;
        box-shadow: unset; }
        #wrapper #content .component.hearing-test-result .component.two-section-grid > .component-content .component.form.hearing-test-result-form .component-content .scfForm .scfSectionContent {
          padding: 0; }
        #wrapper #content .component.hearing-test-result .component.two-section-grid > .component-content .component.form.hearing-test-result-form .component-content .scfForm label {
          color: #ffffff;
          font-weight: 400;
          line-height: 1.6;
          font-size: 1.15rem; }
        #wrapper #content .component.hearing-test-result .component.two-section-grid > .component-content .component.form.hearing-test-result-form .component-content .scfForm input[type='text'] {
          background-color: #ffffff;
          display: inline-block;
          font-size: 1rem;
          padding: 0.8rem 1rem 1rem;
          border: 2px solid #b4b5b7;
          border-radius: 4px;
          font-family: Open Sans, Arial, sans-serif;
          font-weight: 400;
          font-style: normal;
          color: #4d4d4d;
          line-height: 1; }
        #wrapper #content .component.hearing-test-result .component.two-section-grid > .component-content .component.form.hearing-test-result-form .component-content .scfForm input[type='checkbox'] {
          width: 1.5rem;
          height: 1.5rem; }
        #wrapper #content .component.hearing-test-result .component.two-section-grid > .component-content .component.form.hearing-test-result-form .component-content .scfForm .scfValidationSummary {
          display: none; }
        #wrapper #content .component.hearing-test-result .component.two-section-grid > .component-content .component.form.hearing-test-result-form .component-content .scfForm .scfSubmitButtonBorder {
          text-align: right;
          padding: 0; }
          #wrapper #content .component.hearing-test-result .component.two-section-grid > .component-content .component.form.hearing-test-result-form .component-content .scfForm .scfSubmitButtonBorder input[type='submit'] {
            background-color: #f7941d;
            border-radius: 4px;
            border: 0;
            border-bottom: 3px solid #d97a08;
            color: #ffffff;
            padding: 1rem 2rem;
            transition: background-color 0.3s ease;
            padding: 0.5rem 2rem;
            font-size: 1.15rem;
            font-weight: 400;
            font-style: normal;
            display: inline-block;
            width: auto; }
            #wrapper #content .component.hearing-test-result .component.two-section-grid > .component-content .component.form.hearing-test-result-form .component-content .scfForm .scfSubmitButtonBorder input[type='submit']:hover {
              background-color: #f9ab4e; }
            #wrapper #content .component.hearing-test-result .component.two-section-grid > .component-content .component.form.hearing-test-result-form .component-content .scfForm .scfSubmitButtonBorder input[type='submit']:focus {
              box-shadow: 0px 0px 6px 0px #1890ba; }
            #wrapper #content .component.hearing-test-result .component.two-section-grid > .component-content .component.form.hearing-test-result-form .component-content .scfForm .scfSubmitButtonBorder input[type='submit']:active, #wrapper #content .component.hearing-test-result .component.two-section-grid > .component-content .component.form.hearing-test-result-form .component-content .scfForm .scfSubmitButtonBorder input.nice-select.open[type='submit'] {
              border-top: 3px solid #d97a08;
              border-bottom: 0;
              border-left: 3px solid #d97a08; }
        #wrapper #content .component.hearing-test-result .component.two-section-grid > .component-content .component.form.hearing-test-result-form .component-content .scfForm .scfRequired {
          display: none; }
        #wrapper #content .component.hearing-test-result .component.two-section-grid > .component-content .component.form.hearing-test-result-form .component-content .scfForm .scfCheckbox {
          display: flex; }
          #wrapper #content .component.hearing-test-result .component.two-section-grid > .component-content .component.form.hearing-test-result-form .component-content .scfForm .scfCheckbox a {
            color: #f7941d; }
            #wrapper #content .component.hearing-test-result .component.two-section-grid > .component-content .component.form.hearing-test-result-form .component-content .scfForm .scfCheckbox a:hover {
              text-decoration: underline; }
        #wrapper #content .component.hearing-test-result .component.two-section-grid > .component-content .component.form.hearing-test-result-form .component-content .scfForm .scfFooterBorder {
          padding: 0; }
          #wrapper #content .component.hearing-test-result .component.two-section-grid > .component-content .component.form.hearing-test-result-form .component-content .scfForm .scfFooterBorder p {
            font-style: normal;
            line-height: 1.6;
            font-size: 1.15rem; }

.button-white {
  background-color: #ffffff;
  border-radius: 4px;
  border: 0;
  border-bottom: 3px solid #e6e6e6;
  color: #ffffff;
  padding: 1rem 2rem;
  transition: background-color 0.3s ease;
  color: #1890ba;
  font-size: 1.2143rem;
  display: inline-block; }
  .button-white:hover {
    background-color: white; }
  .button-white:focus {
    box-shadow: 0px 0px 6px 0px #1890ba; }
  .button-white:active, .button-white.nice-select.open {
    border-top: 3px solid #e6e6e6;
    border-bottom: 0;
    border-left: 3px solid #e6e6e6; }

.image-spot .component-content .image-spot-elements .image-spot-element-text, .image-spot .component-content .image-spot-elements .image-spot-element-header {
  max-width: 80%;
  margin: auto; }

.image-spot {
  margin-bottom: 2rem; }
  .image-spot .component-content {
    padding: 2rem;
    width: 100%;
    margin: 0 auto; }
    .image-spot .component-content ul {
      list-style: none; }
    .image-spot .component-content li {
      margin-top: 0;
      line-height: normal; }
    @media (min-width: 1200px) {
      .image-spot .component-content {
        position: relative;
        width: 90.25rem;
        max-width: 1140px; } }
    .image-spot .component-content .image-spot-elements {
      text-align: center;
      position: relative; }
      .image-spot .component-content .image-spot-elements .image-spot-element {
        list-style: none;
        margin-bottom: 2rem;
        overflow: hidden;
        text-indent: 0;
        padding-left: 0; }
      .image-spot .component-content .image-spot-elements .image-spot-element-image {
        padding: 0 25%; }
        .image-spot .component-content .image-spot-elements .image-spot-element-image * {
          max-width: 100%;
          height: auto;
          width: auto; }
        .image-spot .component-content .image-spot-elements .image-spot-element-image:not(:empty) {
          margin-bottom: 1rem; }
      .image-spot .component-content .image-spot-elements .image-spot-element-link:not(:empty) {
        margin-top: 1rem; }
  .image-spot.slim .component-content {
    margin: 0 auto;
    width: 100%; }
    @media (min-width: 1200px) {
      .image-spot.slim .component-content {
        position: relative;
        width: 77rem;
        max-width: 1140px; } }
  .image-spot.round-spot .image-spot-element-image img {
    display: inline-block;
    border-radius: 50%; }

@media only screen and (min-width: 992px) {
  .image-spot .component-content .image-spot-elements {
    /* one item */
    /* two items */
    /* three items */
    /* four items */
    /* five items */
    /* six items */
    /* seven items */
    /* eight items */
    /* nine items */
    /* ten items */
    /* two items */
    /* three items */
    /* four items */
    /* five items */ }
    .image-spot .component-content .image-spot-elements > li:first-child:nth-last-child(1) {
      width: 100%;
      float: left; }
    .image-spot .component-content .image-spot-elements > li:first-child:nth-last-child(2),
    .image-spot .component-content .image-spot-elements > li:first-child:nth-last-child(2) ~ li {
      width: 50%;
      float: left; }
    .image-spot .component-content .image-spot-elements > li:first-child:nth-last-child(3),
    .image-spot .component-content .image-spot-elements > li:first-child:nth-last-child(3) ~ li {
      width: 33.3333%;
      float: left; }
    .image-spot .component-content .image-spot-elements > li:first-child:nth-last-child(4),
    .image-spot .component-content .image-spot-elements > li:first-child:nth-last-child(4) ~ li {
      width: 25%;
      float: left; }
    .image-spot .component-content .image-spot-elements > li:first-child:nth-last-child(5),
    .image-spot .component-content .image-spot-elements > li:first-child:nth-last-child(5) ~ li {
      width: 20%;
      float: left; }
    .image-spot .component-content .image-spot-elements > li:first-child:nth-last-child(6),
    .image-spot .component-content .image-spot-elements > li:first-child:nth-last-child(6) ~ li {
      width: 16.6666%;
      float: left; }
    .image-spot .component-content .image-spot-elements > li:first-child:nth-last-child(7),
    .image-spot .component-content .image-spot-elements > li:first-child:nth-last-child(7) ~ li {
      width: 14.2857%;
      float: left; }
    .image-spot .component-content .image-spot-elements > li:first-child:nth-last-child(8),
    .image-spot .component-content .image-spot-elements > li:first-child:nth-last-child(8) ~ li {
      width: 12.5%;
      float: left; }
    .image-spot .component-content .image-spot-elements > li:first-child:nth-last-child(9),
    .image-spot .component-content .image-spot-elements > li:first-child:nth-last-child(9) ~ li {
      width: 11.1111%;
      float: left; }
    .image-spot .component-content .image-spot-elements > li:first-child:nth-last-child(10),
    .image-spot .component-content .image-spot-elements > li:first-child:nth-last-child(10) ~ li {
      width: 10%;
      float: left; }
    .image-spot .component-content .image-spot-elements > li:first-child:nth-last-child(2) .image-spot-element-image,
    .image-spot .component-content .image-spot-elements > li:first-child:nth-last-child(2) ~ li .image-spot-element-image {
      padding: 0 2.5rem; }
    .image-spot .component-content .image-spot-elements > li:first-child:nth-last-child(3) .image-spot-element-image,
    .image-spot .component-content .image-spot-elements > li:first-child:nth-last-child(3) ~ li .image-spot-element-image {
      padding: 0 1.25rem; }
    .image-spot .component-content .image-spot-elements > li:first-child:nth-last-child(4) .image-spot-element-image,
    .image-spot .component-content .image-spot-elements > li:first-child:nth-last-child(4) ~ li .image-spot-element-image {
      padding: 0 0.9375rem; }
    .image-spot .component-content .image-spot-elements > li:first-child:nth-last-child(5) .image-spot-element-image,
    .image-spot .component-content .image-spot-elements > li:first-child:nth-last-child(5) ~ li .image-spot-element-image {
      padding: 0 0.625rem; }
    .image-spot .component-content .image-spot-elements .image-spot-element {
      margin-bottom: 0; }
  .image-spot .component-content .image-spot-element-context {
    display: none; } }

/*# sourceMappingURL=main.[hash].css.map */